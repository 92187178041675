import React from "react";

import { Link } from "react-router-dom";
import { Button } from "primereact/button";
import { Sidebar } from "primereact/sidebar";

import "primereact/resources/themes/nova-light/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";

import UserStore from "../stores/UserStore";

class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      drawerVisible: false,
    };

    if (UserStore.isLoggedIn === false) {
      window.location.href = "/login";
    }
  }

  render() {
    return (
      <div>
        <div className={"header"}>
          <div style={{ flex: 1 }}>
            <Button
              style={{
                fontSize: "24px",
                margin: 5,
                borderStyle: "none",
                backgroundColor: "#A50043",
              }}
              icon="pi pi-bars"
              onClick={(e) => this.setState({ drawerVisible: true })}
            />
          </div>
          <div style={{ flex: 3, display: "flex", justifyContent: "center" }}>
            <a className="p-as-center" href="/">
              <img alt="logo" src={require("../images/logo.svg")} />
            </a>
          </div>
          <div style={{ flex: 1 }} />
        </div>
        <Sidebar
          visible={this.state.drawerVisible}
          onHide={() => this.setState({ drawerVisible: false })}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              paddingTop: "10px",
              paddingBottom: "10px",
            }}
          >
            <img
              alt="logo"
              style={{ alignSelf: "center" }}
              src={require("../images/logo.svg")}
            />
          </div>
          <p style={{ color: "white" }}>
            <i
              style={{ color: "#FFD600", marginRight: 10, fontSize: "1.2em" }}
              className="pi pi-user"
            />
            {UserStore.name}
          </p>
          <div className="spreader" />
          <li>
            <i
              style={{}}
              className="pi pi-home mainNavIcons"
            ></i>
            <Link
              to="/"
              onClick={(e) => this.setState({ drawerVisible: false })}
            >
              Home
            </Link>
          </li>
          <li>
            <i
              style={{}}
              className="pi pi-list mainNavIcons"
            ></i>
            <Link
              to="/orders"
              onClick={(e) => this.setState({ drawerVisible: false })}
            >
              Orders
            </Link>
          </li>
          <li>
            <i
              style={{}}
              className="pi pi-shopping-cart mainNavIcons"
            ></i>
            <Link
              to="/cart"
              onClick={(e) => this.setState({ drawerVisible: false })}
            >
              Cart
            </Link>
          </li>
          {/* <li>
            <i
              style={{}}
              className="pi pi-money-bill mainNavIcons"
            ></i>
            <Link
              to="/cards"
              onClick={(e) => this.setState({ drawerVisible: false })}
            >
              Cards
            </Link>
          </li> */}
          <li>
            <i
              style={{}}
              className="pi pi-user-edit mainNavIcons"
            ></i>
            <Link
              to="/user"
              onClick={(e) => this.setState({ drawerVisible: false })}
            >
              Edit Profile
            </Link>
          </li>
          <li>
            <i
              style={{}}
              className="pi pi-lock mainNavIcons"
            ></i>
            <Link
              to="/privacy"
              onClick={(e) => this.setState({ drawerVisible: false })}
            >
              Privacy Policy
            </Link>
          </li>
          <li>
            <i
              style={{}}
              className="pi pi-sign-out mainNavIcons"
            ></i>
            <Link
              to="/logout"
              onClick={(e) => this.setState({ drawerVisible: false })}
            >
              Logout
            </Link>
          </li>
          {/* <li>
            <Link to="/" onClick={this.props.resetSector}>
              Change Sector
            </Link>
          </li> */}
        </Sidebar>
      </div>
    );
  }
}

export default Header;
