import React from "react";

import { Button } from "primereact/button";
// import { Dialog } from 'primereact/dialog';
import { Dropdown } from "primereact/dropdown";
// import { Card } from 'primereact/card';
import { InputMask } from "primereact/inputmask";
import "primereact/resources/themes/nova-light/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import "primeflex/primeflex.css";
import { Checkbox } from "primereact/checkbox";
import { callApi } from "../services/ApiService";
import Header from "../components/Header";
import UserStore from "../stores/UserStore";

class SectorSelectForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: "",
      data: [],
      loading: true,
      stand: 0,
      standName: "",
      sector: 0,
      sectorName: "",
      remember: false,
      telephone: " ",
      //telephoneError: '',
    };
  }

  componentDidMount() {
    this.getStandsAndSectors();
  }

  getStandsAndSectors = () => {
    callApi("getMerchants", "get", true)
      .then((data) => {
        let merchantData;
        // let locations;
        data.forEach((element) => {
          if (element.id === UserStore.data.merchant_id) {
            merchantData = element;
          }
        });

        if (typeof data == "undefined") {
          setTimeout(() => {
            this.getStandsAndSectors();
          }, 3000); // retry the call
        } else {
          this.setState({ data: merchantData.locations, loading: false }); // success
        }
      })
      .catch((e) => {
        // console.log(e);
        this.setState({ loading: false });
      });
  };

  standsDropdown = () => {
    let standSelectItems = [];
    this.state.data.forEach((stand) => {
      standSelectItems.push({ label: stand.name, value: stand.id });
    });
    return (
      <div className="p-field">
        {/* <label htmlFor="standList">Stand</label> */}
        <Dropdown
          id="standList"
          value={this.state.stand}
          options={standSelectItems}
          onChange={(e) => {
            this.standSelected(e);
          }}
          placeholder="Τμήμα / Section *"
        />
      </div>
    );
  };

  sectorsDropdown = () => {
    let sectorSelectItems = [];
    if (this.state.stand > 0) {
      this.state.selectedStandSectors.forEach((sector) => {
        sectorSelectItems.push({ label: sector.name, value: sector.id });
      });
    }
    return (
      <div className="p-field">
        {/* <label htmlFor="sectorList">Sector</label> */}
        <Dropdown
          id="sectorList"
          value={this.state.sector}
          options={sectorSelectItems}
          onChange={(e) => {
            this.sectorSelected(e);
          }}
          placeholder="Tομέας / Sector"
        />
      </div>
    );
  };

  telephoneField = () => {
    return (
      <div className="p-field telephone-field">
        {/* <label htmlFor="telephone">Telephone</label> */}
        <InputMask
          mask="99-999999"
          value={this.state.telephone}
          onChange={(e) => this.setState({ telephone: e.value })}
          placeholder="Τηλέφωνο / Telephone *"
        ></InputMask>
        <span style={{ fontSize: "0.8em" }} className="submessage">
          Θα λάβετε SMS όταν η παραγγελία σας είναι έτοιμη
        </span>
      </div>
    );
  };

  standSelected = (e) => {
    let selectedStand = this.state.data.find((obj) => {
      return obj.id === e.value;
    });
    this.setState({
      stand: e.value,
      standName: selectedStand.name,
      selectedStandSectors: selectedStand.sectors,
      sector: 0,
      error: "",
    });
  };

  sectorSelected = (e) => {
    let selectedSector = this.state.selectedStandSectors.find((obj) => {
      return obj.id === e.value;
    });

    this.setState({
      sector: e.value,
      sectorName: selectedSector.name,
      error: "",
    });
    //UserStore.data.sector_id = e.value;
  };

  submitSector = () => {

    if (
      this.state.sector > 0 &&
      this.state.telephone !== " " &&
      this.state.telephone !== "" &&
      this.state.telephone !== null
    ) {
      UserStore.data.stand_id = this.state.stand;
      UserStore.data.stand_name = this.state.standName;
      UserStore.data.sector_id = this.state.sector;
      UserStore.data.sector_name = this.state.sectorName;
      UserStore.data.cart = []; // empty the cart on sector change
      UserStore.data.telephone = this.state.telephone;
      localStorage.setItem("UserStore", JSON.stringify(UserStore));
      if (this.state.remember)
        localStorage.setItem(
          "UserPosition",
          JSON.stringify({
            stand_id: this.state.stand,
            sector_id: this.state.sector,
            sector_name: this.state.sectorName,
            stand_name: this.state.standName,
            telephone: this.state.telephone,
          })
        );
    } else {
      if (
        this.state.telephone === " " ||
        this.state.telephone === "" ||
        this.state.telephone == null
      ) {
        this.setState({ error: "Please fill the telephone" });
      }

      if (this.state.sector === 0) {
        this.setState({ error: "Please fill the sector" });
      }
    }
  };

  onRememberChange = () => {
    this.setState({ remember: !this.state.remember });
  };
  render() { 
    return (
      <div>
        <Header />

        <div
          className="sectorHeader"
          style={{ display: "flex", marginTop: 70 }}
        >
          <div className="circle">
            <img alt="location" src={require("../images/location.png")} />
          </div>
          <h2>Find my Canteen</h2>
        </div>

        <div style={{ margin: 40 }}>
          <div className="p-fluid">
            {this.standsDropdown()}
            {this.sectorsDropdown()}
            {this.telephoneField()}
            <Checkbox
              className="p-chkbox-box remember-chkbox"
              inputId="cb1"
              onChange={this.onRememberChange}
              disabled={this.state.sector === 0 || this.state.stand === 0}
              checked={this.state.remember}
            ></Checkbox>
            <label htmlFor="cb1" className="p-checkbox-label">
              Αποθήκευση Θέσης / Remember my Seat
            </label>
            <div style={{ marginBottom: 15, color: "red" }}>
              {this.state.error}
            </div>
            <Button
              label="Επιβεβαίωση / Confirm"
              className="p-button-success sectorButton"
              onClick={() => this.submitSector()}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default SectorSelectForm;
