import React from "react";
import { callApi } from "../services/ApiService";
import { ProgressSpinner } from "primereact/progressspinner";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
// import {RadioButton } from 'primereact/radiobutton';

class CardsSelector extends React.Component {
  constructor() {
    super();
    this.state = {
      cards: "",
      deleteModal: false,
      cardId: "",
      cardsModal: false,
    };
  }

  componentDidMount() {
    this.fetchUserCreditCards();
  }
  fetchUserCreditCards = () => {
    callApi("fetchCards", "get", true).then((res) => {
      if (res.status === "success") {
        this.setState({ cards: res.cards });
      }
    });
  };

  deleteCardFromState = () => {
    const { cards, cardId } = this.state;
    this.setState({ cards: cards.filter((card) => card.id !== cardId) });
  };

  deleteCard = () => {
    callApi("deleteCard", "post", true, [{ cardId: this.state.cardId }]).then(
      (res) => {
        if (res.status === "success") {
          this.hideModal();
          this.deleteCardFromState();
        }
      }
    );
  };

  showDeleteModal = (id) => {
    this.setState({ deleteModal: true, cardId: id });
  };
  hideModal = () => {
    this.setState({ deleteModal: false });
  };

  renderFooter = () => {
    return (
      <div>
        <Button
          label="No"
          icon="pi pi-times"
          onClick={() => this.hideModal()}
          className="p-button-text"
        />
        <Button
          style={{
            border: "1px solid #a50043",
            backgroundColor: "rgb(165, 0, 67)",
          }}
          label="Yes"
          icon="pi pi-check"
          onClick={() => this.deleteCard()}
          autoFocus
        />
      </div>
    );
  };
  onCardChange = (i) => {
    let singleCard = {
      index: i,
      cardDigits: this.state.cards[i].card_digits,
      cardToken: this.state.cards[i].card_token,
    };
    this.props.onChangeCard(singleCard);
    this.setState({ cardsModal: false });
  };
  render() {
    const { cards } = this.state;

    return (
      <>
        {!cards ? (
          <ProgressSpinner
            style={{ width: "50px", height: "50px", overflow: "hidden" }}
            strokeWidth="8"
            fill="#EEEEEE"
            animationDuration=".5s"
          />
        ) : cards.length === 0 ? (
          this.props.deleteOption ? (
            <p>No cards available</p>
          ) : null
        ) : this.props.deleteOption ? (
          <div>
            {cards.map((card) => (
              <div className="p-d-flex p-m-3 credit-card">
                <div className="p-as-center" style={{ flex: 3 }}>
                  <p style={{ fontWeight: "bold" }}>{card.card_holder}</p>
                  <p>Card ending in *{card.card_digits}</p>
                </div>
                <div
                  className="p-as-center p-d-flex"
                  style={{ flex: 1, justifyContent: "center" }}
                >
                  <i
                    onClick={() => this.showDeleteModal(card.id)}
                    className="pi pi-trash"
                  />
                </div>
              </div>
            ))}
            <Dialog
              header="Delete Card"
              visible={this.state.deleteModal}
              style={{ width: "70vw" }}
              footer={this.renderFooter()}
              onHide={() => this.hideModal()}
            >
              Are you sure?
            </Dialog>
          </div>
        ) : (
          <div>
            <div
              onClick={() => this.setState({ cardsModal: true })}
              className="p-d-flex viva-container"
            >
              <p style={{ fontWeight: "bold" }}>
                {this.props.selectedCard
                  ? `Card Ending in *${this.props.selectedCard.cardDigits}`
                  : "Select Credit Card"}
              </p>
              <img alt="viva-logo" src={require("../images/viva_logo.svg")} />
            </div>

            <Dialog
              dismissableMask
              header="Select Card"
              visible={this.state.cardsModal}
              style={{ width: "90vw", padding: 0 }}
              onHide={() => this.setState({ cardsModal: false })}
            >
              {cards.map((card, i) => (
                <div
                  style={{
                    backgroundColor:
                      this.props.selectedCard.index === i ? "#FFD600" : "white",
                  }}
                  onClick={() => this.onCardChange(i)}
                  key={i}
                  className="p-d-flex p-m-1, p-mb-2 credit-card"
                >
                  <div className="p-as-center" style={{ flex: 3 }}>
                    <p style={{ fontWeight: "bold" }}>{card.card_holder}</p>
                    <p>Card ending in *{card.card_digits}</p>
                  </div>
                  <div
                    className="p-as-center p-d-flex"
                    style={{ flex: 1, justifyContent: "center" }}
                  >
                    {this.props.selectedCard.index === i ? (
                      <i className="pi pi-circle-on" />
                    ) : (
                      <i className="pi pi-circle-off" />
                    )}
                  </div>
                </div>
              ))}
            </Dialog>
          </div>
        )}
      </>
    );
  }
}

export default CardsSelector;
