import React from "react";
import PrivacyPolicyComponent from "../components/PrivacyPolicyComponent";
import { Dialog } from "primereact/dialog";
import { callApi } from "../services/ApiService";
import UserStore from "../stores/UserStore";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { Password } from "primereact/password";
class Register extends React.Component {
  constructor() {
    super();
    this.state = {
      privacy: false,
      r_name: "",
      r_username: "",
      r_password: "",
      rc_password: "",
      errors: "",
    };
  }

  registerUser = () => {
    callApi("register", "post", false, [
      { name: this.state.r_name },
      { email: this.state.r_username },
      { password: this.state.r_password },
      { password_confirmation: this.state.rc_password },
    ])
      .then((data) => {
        if (typeof data == "undefined") {
          UserStore.isLoggedIn = false;
          localStorage.clear();
        } else if (data.status === 422) {
          this.setState({ errors: data.data.errors });
        } else {
          UserStore.isLoggedIn = true;
          UserStore.access_token = data.access_token;
          UserStore.name = data.name;
          localStorage.setItem("UserStore", JSON.stringify(UserStore));
          window.location.href = "/";
        }
      })
      .catch((err) => {});
  };

  handleFocus = (input) => {
    let errors = { ...this.state.errors };
    errors[input] = "";
    this.setState({ errors });
  };

  render() {
    const myIcon = (
      <button
        onClick={() => this.setState({ privacy: 0 })}
        className="p-dialog-titlebar-icon p-link"
      >
        <span className="pi pi-chevron-left"></span>
      </button>
    );

    return (
      <div>
        <Dialog
          style={{ width: "95vw" }}
          className="registerDialog"
          iconsTemplate={this.state.privacy ? myIcon : null}
          closable
          onHide={() => this.props.hideRegisterModal()}
          visible={this.props.registerModal}
        >
          {!this.state.privacy ? (
            <div>
              <div className="p-as-center p-mb-3">
                <InputText
                  onFocus={() => this.handleFocus("name")}
                  placeholder={"Name*"}
                  className={
                    `txt-input ` +
                    (this.state.errors.name ? "invalid-input" : null)
                  }
                  value={this.state.r_name}
                  onChange={(e) => this.setState({ r_name: e.target.value })}
                />
                {this.state.errors && this.state.errors.name ? (
                  <span style={{ color: "red" }}>{this.state.errors.name}</span>
                ) : null}
              </div>
              <div className="p-as-center p-mb-3">
                <InputText
                  onFocus={() => this.handleFocus("email")}
                  placeholder={"Email*"}
                  className={
                    `txt-input ` +
                    (this.state.errors.email ? "invalid-input" : null)
                  }
                  value={this.state.r_username}
                  onChange={(e) =>
                    this.setState({ r_username: e.target.value })
                  }
                  feedback={false}
                />
                {this.state.errors && this.state.errors.email ? (
                  <span style={{ color: "red" }}>
                    {this.state.errors.email}
                  </span>
                ) : null}
              </div>
              <div className="p-as-center p-mb-3">
                <Password
                  onFocus={() => this.handleFocus("password")}
                  placeholder={"Password*"}
                  className={
                    `txt-input ` +
                    (this.state.errors.password ? "invalid-input" : null)
                  }
                  value={this.state.r_password}
                  onChange={(e) =>
                    this.setState({ r_password: e.target.value })
                  }
                  feedback={false}
                />
                {this.state.errors && this.state.errors.password ? (
                  <span style={{ color: "red" }}>
                    {this.state.errors.password}
                  </span>
                ) : null}
              </div>
              <div className="p-as-center p-mb-3">
                <Password
                  onFocus={() => this.handleFocus("password")}
                  placeholder={"Confirm Password*"}
                  className={
                    `txt-input ` +
                    (this.state.errors.password ? "invalid-input" : null)
                  }
                  value={this.state.rc_password}
                  onChange={(e) =>
                    this.setState({ rc_password: e.target.value })
                  }
                  feedback={false}
                />
              </div>
              <Button
                disabled={this.state.r_password !== this.state.rc_password}
                onClick={this.registerUser}
                style={{
                  backgroundColor: "#178700",
                  border: "1px solid #178700",
                  width: "100%",
                }}
                label={"Register "}
              />

              <p style={{ fontStyle: "italics" }}>
                *The password should be at least 8 characters
              </p>
              <p
                style={{ color: "#007ad9" }}
                onClick={() => this.setState({ privacy: 1 })}
                className="p-mt-4 p-text-center"
              >
                Read our Terms and Conditions
              </p>
            </div>
          ) : (
            <PrivacyPolicyComponent />
          )}
        </Dialog>
      </div>
    );
  }
}

export default Register;
