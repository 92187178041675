import React from "react";
import Header from "../components/Header";

import { callApi } from "../services/ApiService";
import { InputText } from "primereact/inputtext";
import { Link } from "react-router-dom";
import { Button } from "primereact/button";

class EditUser extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      name: '',
      email: '',
      mobile_phone: '',
      response_msg: '',
      loading: true,
    };
  }

  componentDidMount() {
    this.getUserDetails();
  }

  getUserDetails = () => {
    callApi("user", "get", true)
      .then((data) => {
        if (typeof data == "undefined") {
          setTimeout(() => {
            this.getUserDetails();
          }, 3000); // retry the call
        } else {
          this.setState({ data: data[0],name: data[0].name,email: data[0].email,mobile_phone: data[0].mobile_phone, loading: false }); // success
        }
      })
      .catch((e) => {
        this.setState({ loading: false });
      });
  };

  updateUserDetails = () => {
    callApi("userUpdate", "post", true, [
      { name: this.state.name },
      { email: this.state.email },
      { mobile_phone: this.state.mobile_phone },
    ])
      .then((data) => {
        this.setState({ response_msg: data.msg });
      })
      .catch((err) => {});
  };

  render() {
    return (
      <div>
        <Header />
        <h2 style={{ marginTop: 70 }} className="cartTitle">
          <Link to="/">
            <button
              style={{
                cursor: "pointer",
                borderStyle: "none",
              }}
              className="pi pi-angle-left cartButton"
            ></button>
          </Link>
          <span className="cartTitleSpan">Edit Profile</span>
        </h2>
        <div className="edit-user-page" style={{ margin: 40 }}>
          <div className="p-fluid">
            <div className="p-as-center p-mb-3">
              <label>Name</label>
              <InputText
                value={this.state.name}
                onChange={(e) => this.setState({ name: e.target.value })}
              />
            </div>
            <div className="p-as-center p-mb-3">
              <label>Email</label>
              <InputText
                disabled={!this.state.data.provider ? false : true}
                value={this.state.email}
                onChange={(e) => this.setState({ email: e.target.value })}
              />
            </div>
            <div className="p-as-center p-mb-3">
              <label>Mobile Phone</label>
              <InputText
                value={this.state.mobile_phone}
                onChange={(e) => this.setState({ mobile_phone: e.target.value })}
              />
            </div>
            <div className="p-as-center p-mb-3">
              <Button
                // disabled={this.state.r_password !== this.state.rc_password}
                onClick={this.updateUserDetails}
                label={"Update Details"}
              />
            </div>
            <h3 className="response_message">{this.state.response_msg}</h3>
          </div>
        </div>
      </div>
    );
  }
}

export default EditUser;
