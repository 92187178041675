import React from "react";
import { BrowserRouter, Route } from "react-router-dom";
import { observer } from "mobx-react";

import UserStore from "./stores/UserStore";

import Login from "./screens/Login";
import Logout from "./screens/Logout";
import HomeNew from "./screens/HomeNew";
import Orders from "./screens/Orders";
import Cart from "./screens/Cart";
import EditUser from "./screens/EditUser";
import Checkout from "./screens/Checkout";
import PaymentSuccess from "./screens/PaymentSuccess";
import Cards from "./screens/Cards";
import PrivacyPolicy from "./screens/PrivacyPolicy";
import ReactGA from "react-ga";

ReactGA.initialize("UA-178667365-1");
ReactGA.pageview(window.location.pathname + window.location.search);
class App extends React.Component {
  // constructor(props){
  //   super(props);
  // }

  componentDidMount() {
    this.setUserPosition();
  }

  setUserPosition = () => {
    if (localStorage.getItem("UserPosition") != null) {
      var userPosition = JSON.parse(localStorage.getItem("UserPosition")); 
      if (userPosition) {
        UserStore.data.sector_id = userPosition.sector_id;
        UserStore.data.stand_id = userPosition.stand_id;
        UserStore.data.stand_name = userPosition.stand_name;
        UserStore.data.sector_name = userPosition.sector_name;
        UserStore.data.telephone = userPosition.telephone;
      }
    }
  };

  render() {
    if (localStorage.getItem("UserStore") != null) {
      var localData = JSON.parse(localStorage.getItem("UserStore"));
      UserStore.isLoggedIn = localData.isLoggedIn;
      UserStore.access_token = localData.access_token;
      UserStore.name = localData.name;
      UserStore.data = localData.data;
      // console.log(localData.data.cart);
    }

    return (
      <BrowserRouter>
        <Route path="/" exact component={HomeNew} />
        <Route path="/login" exact component={Login} />
        <Route path="/logout" exact component={Logout} />
        <Route path="/orders" exact component={Orders} />
        <Route path="/cart" exact component={Cart} />
        <Route path="/payment-success" exact component={PaymentSuccess} />
        <Route path="/checkout" exact component={Checkout} />
        {/* <Route path="/cards" exact component={Cards} /> */}
        <Route path="/user" exact component={EditUser} />
        <Route path="/privacy" exact component={PrivacyPolicy} />
      </BrowserRouter>
    );
  }
}

export default observer(App);
