import axios from "axios";
import UserStore from "../stores/UserStore";

const baseUrl = "https://kantinapp-backend-staging.tabsandspaces.agency";
// const baseUrl = "https://canteens-new.tabsandspaces.agency";
//  const baseUrl = "http://canteens.test";
//  const baseUrl = "https://canteens.ddev.site";

export async function callApi(call, method, authRequired, bodyParams = []) {
  let url = baseUrl + "/api/" + call;

  let axiosConfig = {
    method: method,
    url: url,
  };

  if (authRequired) {
    axiosConfig.headers = { Authorization: "Bearer " + UserStore.access_token };
  }

  // if request has body parameters
  if (bodyParams.length > 0) {
    var bodyFormData = new FormData();
    bodyParams.forEach(function (param) {
      bodyFormData.append(Object.keys(param), param[Object.keys(param)]);
    });

    axiosConfig.data = bodyFormData;
    axiosConfig.config = { headers: { "Content-Type": "multipart/form-data" } };
  }

  return axios(axiosConfig)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      if (error.response.status === 401) {
        localStorage.clear();
        window.location.href = "/login";
      } else {
        return error.response;
      }
    });
}

export function getBaseUrl() {
  return baseUrl;
}
