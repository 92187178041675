import React from "react";
// import { observer } from 'mobx-react';

import { Button } from "primereact/button";
// import { Dialog } from 'primereact/dialog';
import { InputNumber } from "primereact/inputnumber";
import { Sidebar } from "primereact/sidebar";
import { RadioButton } from "primereact/radiobutton";

import "primereact/resources/themes/nova-light/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import "primeflex/primeflex.css";
import _ from 'lodash';
import { callApi } from "../services/ApiService";
import { displayPrice, handleLabel } from "../utilities/Utilities";
import "../App.css";

import UserStore from "../stores/UserStore";

class CanteenMenu extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: "",
      data: {},
      loading: true,
      showProductPopup: false,
      product: {},
      addons: [],
      selectedQuantity: 1,
      overQuantity: false,
    };
  }

  componentDidMount() {
    this.getSectorBestCanteen();
    UserStore.total = 0;
  }

  getSectorBestCanteen = () => {
    callApi("getCanteenMenu", "post", true, [
      {
        data: JSON.stringify({
          sector_id: UserStore.data.sector_id,
          location_id: UserStore.data.stand_id,
          mobile_phone: UserStore.data.telephone
            ? UserStore.data.telephone.replace(/-/g, "")
            : "",
        }),
      },
    ])
      .then((data) => {
        if (data.hasOwnProperty("error")) {
          this.setState({ error: data.error, loading: false });
        }

        // if (data.categories.length === 0) {
        //   this.setState({ error: data.error, loading: false });
        // }
        // let products_list_initial = [];
        let products_list = [];
        data.categories.forEach((element) => {
          // products_list_initial.push(element);
          element.products.forEach((element2) => {
            element2['category_position'] = element.position;
            products_list.push(element2);
          });
        });

        if (typeof data == "undefined") {
          setTimeout(() => {
            this.getSectorBestCanteen();
          }, 3000); // retry the call
        } else {
          if (data.hasOwnProperty("error")) {
            this.setState({ error: data.error, loading: false });
          } else if (data.categories.length === 0) {
            this.setState({
              error: "No Available Products/Categories",
              loading: false,
            });
          } else {

            let categorized_products = []
            var today = new Date(),
            today_date = today.getFullYear() + '-' + ('0' + (today.getMonth() + 1)).slice(-2) + '-' + today.getDate();
        
            var grouped_products = _.groupBy(products_list,'category_position'); 

            Object.entries(grouped_products).forEach(([key, value]) => {
              data.categories.find(function (element) {
                if(element.position == key){
                  categorized_products.push(element.name)
                }
                return null;
              });
              
              value.map(prod => {
                categorized_products.push(prod)
                return null;
              })

            })
            

            data.discounts.forEach((dp) => {
              if(today_date >= dp.start_date && today_date <= dp.end_date) {
                if(dp.product_specific == 0) {
                  categorized_products.forEach((cp) => {
                    if(typeof cp.price !== 'undefined') {
                      // cp.price = cp.price - (cp.price * (dp.percentage/100))
                      cp['old_price'] = cp.price;
                      cp['price'] = cp.price - (cp.price * (dp.percentage/100));
                    }
                  })
                } else {
                  dp.products.forEach((id_prod) => { 
                    categorized_products.find(function (element) {
                      if(element.id == id_prod.id){
                        // element.price = id_prod.price - (id_prod.price * (dp.percentage/100))
                        element['old_price'] = id_prod.price;
                        element['price'] = id_prod.price - (id_prod.price * (dp.percentage/100));
                      }
                    })
                  })
                }
              }
            })
            
            //data.products_list.sort((a,b)=> (a.sort) - (b.sort));
            this.setState({ data: categorized_products, loading: false }); // success
            UserStore.data.canteen_id = data.id;
            UserStore.data.canteen_name = data.name;
          }
        }
      })
      .catch((e) => {
        this.setState({ loading: false });
      });
  };

  generateMenu = () => {
    
    let products = this.state.data.map((product) => {

      return (
        <div>
        {product.label ? (
          <a
            key={product.label + product.price}
            href="#/"
            onClick={() => {
              this.showProduct(product);
            }}
          >
            <div
              style={{
                borderRadius: "5px",
                border: "1px solid #C4C4C4",
                paddingTop: "20px",
                paddingBottom: "20px",
              }}
              className="p-grid p-align-center menuItem"
            >
              <div className="p-col-9">
                <div style={{ color: "#1B1B1B" }} className="menuItemName">
                  {product.label}
                </div>
                {product.description ? (
                  <div
                    style={{ color: "#424242" }}
                    className="menuItemDescription"
                  >
                    {product.description}
                  </div>
                ) : null}
              </div>
              <div className="p-col-3">
                {product.old_price ? (
                  <div
                    style={{
                      borderRadius: "5px",
                      padding: "10px",
                      border: "1px solid #C4C4C4",
                      color: "#1B1B1B",
                      textAlign: "center",
                    }}
                    className="menuItemPrice"
                  >
                    <div style={{textDecoration: 'line-through', color: '#808080', display: 'inline-block', padding: '5px'}}> {displayPrice(product.old_price)}</div>
                    <div style={{display: 'inline-block', padding: '5px'}}> {displayPrice(product.price)}</div>
                  </div>
                ) : (
                  <div
                    style={{
                      borderRadius: "5px",
                      padding: "10px",
                      border: "1px solid #C4C4C4",
                      color: "#1B1B1B",
                      textAlign: "center",
                    }}
                    className="menuItemPrice"
                  >
                    {displayPrice(product.price)}
                  </div>
                )}
              </div>
            </div>
          </a>
        ) : (
          <div
            key={product}
            style={{
              borderRadius: "5px",
              border: "0",
              paddingTop: "20px",
              paddingBottom: "20px",
              fontWeight: "900",
              backgroundColor: "rgb(165, 0, 67)",
              color: "rgb(255, 214, 0)",
              width: "fit-content",
              fontStyle: "italic",
            }}
            className="p-grid p-align-center menuItem"
          >
            {product}
          </div>
        )}
        </div>
      );
    });
    return products;
  };

  showProduct = (product) => {
    this.setState({
      product: product,
      addons: [],
      showProductPopup: true,
      selectedQuantity: 1,
    });
    document.body.style.overflow = "hidden";
  };

  addToCart = () => {
    let overQuantity = false;
    let productAlreadyInCart = false;
    // loop cart items
    UserStore.data.cart.forEach((cartItem) => { 
      // if product is already in cart
      if (cartItem.id === this.state.product.id) {
        // if added product has addons
        if (cartItem.addons.length > 0) {
          // check if the addons selected are the same as the product already in the cart
          // create 2 arrays with the optionIds of the cart item and added item in order to compare them
          var cartItemOptionIds = [];
          var addedItemOptionIds = [];
          cartItem.addons.forEach((cartAddon) => {
            cartItemOptionIds.push(cartAddon.optionId);
          });
          this.state.addons.forEach((addedItemAddon) => {
            addedItemOptionIds.push(addedItemAddon.optionId);
          });

          // product is already in cart with the same addons - increment quantity
          if (
            cartItemOptionIds.length === addedItemOptionIds.length &&
            cartItemOptionIds.sort().every(function (value, index) {
              return value === addedItemOptionIds.sort()[index];
            })
          ) {
            if (cartItem.quantity + this.state.selectedQuantity <= 10) {
              cartItem.quantity =
                cartItem.quantity + this.state.selectedQuantity;
              productAlreadyInCart = true;
            } else {
              overQuantity = true;
            }
          }
        }
        // product was found in cart and has no addons - increment quantity
        else {
          if (cartItem.quantity + this.state.selectedQuantity <= 10) {
            cartItem.quantity = cartItem.quantity + this.state.selectedQuantity;
            productAlreadyInCart = true;
          } else {
            overQuantity = true;
          }
        }
      }
    });

    // product is not already in cart - add it
    if (!productAlreadyInCart) { 
      if(this.state.product.old_price) {
        var has_discount = 1;
      } else {
        var has_discount = 0;
      }
      let discount_price = this.state.product.old_price - this.state.product.price;
    // calculate product price including addons
      let productPrice = this.state.product.price;
      //console.log("this.state.addons: ", this.state.addons);
      this.state.addons.forEach((addon) => {
        if (addon.price > 0) {
          productPrice += addon.price;
        }
      });
     
      // add product to cart
      const cartItemId = Math.random().toString(36).substr(2, 8); // give the item a unique code in order to handle quantity changes
      if (overQuantity === false) {
        UserStore.data.cart.push({ 
          cartItemId: cartItemId,
          id: this.state.product.id,
          label: this.state.product.label,
          price: productPrice,
          quantity: this.state.selectedQuantity,
          addons: this.state.addons,
          discount: has_discount,
          discount_price: discount_price,
        });
      }
    }

    localStorage.setItem("UserStore", JSON.stringify(UserStore));
    this.hideProductPopup(overQuantity);
    return overQuantity;
  };

  checkDisabled = () => {
    let selectedAddons = this.state.addons;
    let productAddons = this.state.product.addons;
    if (productAddons && productAddons.length > 0) {
      var isDisabled = true;
      for (var i = 0; i < productAddons.length; i++) {
        isDisabled = true;
        for (var j = 0; j < selectedAddons.length; j++) {
          if (productAddons[i].name === selectedAddons[j].addonName)
            isDisabled = false;
        }
        if (isDisabled) break;
      }
    } else {
      isDisabled = false;
    }

    return isDisabled;
  };
  calculateExtrasPrice = () => {
    let addons = this.state.addons;
    let price = 0;
    addons.length > 0 &&
      this.state.addons.forEach((addon) => {
        price += addon.price;
      });
    return price;
  };
  renderAddons = () => {
    return (
      <div className="listAddons">
        {this.state.product.addons.map((addon) => {
          return (
            <div>
              <label
                style={{
                  fontWeight: "bold",
                  fontSize: "20px",
                  paddingBottom: 10,
                  paddingTop: 10,
                  display: "block",
                }}
              >
                {addon.label}
              </label>
              {addon.active_options.map((option) => {
                // check if radio button should be selected
                var existingAddonIndex = this.state.addons.findIndex(function (
                  item,
                  i
                ) {
                  return item.addonName === addon.name;
                });
                var checked =
                  existingAddonIndex >= 0
                    ? this.state.addons[existingAddonIndex].optionId ===
                      option.id
                    : false;

                return option.active ? (
                  <div>
                    <RadioButton
                      required={true}
                      style={{ marginBottom: "10px", marginTop: "10px" }}
                      value={option.id}
                      name={option.name}
                      onChange={(e) => {
                        let selectedAddon = {
                          addonName: addon.name,
                          optionId: option.id,
                          optionName: option.name,
                          price: option.price,
                          type: addon.type,
                        };
                        let stateAddons = this.state.addons;

                        // find the index of the addon if an option was already selected in order to replace it with the newly selected option
                        var existingAddonIndex = stateAddons.findIndex(
                          function (item, i) {
                            return item.addonName === addon.name;
                          }
                        );
                        if (existingAddonIndex >= 0) {
                          stateAddons.splice(existingAddonIndex, 1); // remove previously selected option for current addon
                        }

                        stateAddons.push(selectedAddon);
                        this.setState({ addons: stateAddons });
                      }}
                      checked={checked}
                    />
                    <label
                      style={{ marginLeft: "10px", fontSize: "16px" }}
                      htmlFor={option.name}
                    >
                      {handleLabel(option)}
                    </label>
                  </div>
                ) : null;
              })}
            </div>
          );
        })}
      </div>
    );
  };

  hideProductPopup = (overQuantity) => {
    if (!overQuantity) {
      this.setState({ showProductPopup: false, overQuantity: false });
      document.body.style.overflow = "unset";
    } else {
      this.setState({ overQuantity });
    }
  };

  render() {

    return (
      <div className="p-fluid selectorItem">
        <div className="p-d-flex p-flex-column ignore-margin orderTitle">
          <div className="p-d-flex p-flex-row topbar-title-menu">
            <div className="p-d-flex p-flex-row merchant-name-menu">
              <div className="circle">
                <img alt="location" src={require("../images/location.png")} />
              </div>
              <div className="merchant-name">
                {UserStore.data.merchant_name}
              </div>
            </div>
            <div className="sector-name-menu">
              {UserStore.data.stand_name} ({UserStore.data.sector_name})
            </div>
          </div>
          <div style={{ width: "130px" }}>
            <Button
              label="Change Sector"
              style={{
                color: "#1B1B1B",
                backgroundColor: "#FFD600",
                flex: 2,
                fontSize: "14px",
              }}
              className="p-button-success p-mr-2"
              onClick={this.props.resetSector}
            />
          </div>
        </div>
        {this.state.error ? (
          <div style={{ marginBottom: 15, paddingTop: 15, color: "red" }}>
            {this.state.error}
          </div>
        ) : null}
        <h3>{this.state.data.name}</h3>

        {this.state.data.number_of_open_orders >=
          this.state.data.max_number_of_open_orders && (
          <p className="warning-message">
            The canteen is busy. Your order might not be accepted at this time.
          </p>
        )}

        {/* CANTEEN MENU */}
        {!this.state.loading && this.state.error === "" && this.generateMenu()}

        {/* ADD TO CART SIDEBAR */}
        <Sidebar
          className="addSelect"
          visible={this.state.showProductPopup}
          fullScreen={false}
          position="right"
          style={{ width: "100%", marginTop: "55px" }}
          onHide={() => this.hideProductPopup(false)}
        >
          {this.state.product && (
            <div
              style={{
                overflowY: "auto",
                maxHeight: "75%",
                overflowX: "hidden",
                width: "100%",
              }}
            >
              <h3>{this.state.product.label}</h3>
              <div>
                <p style={{ fontWeight: "bold" }}>
                  €{parseFloat(this.state.product.price).toFixed(2)}
                </p>
              </div>
              {this.state.product.description ? (
                <div className="productPopupDescription">
                  {this.state.product.description}
                </div>
              ) : null}
              {this.state.overQuantity ? (
                <p style={{ color: "red" }}>
                  You have reached the maximum quantity for this product{" "}
                </p>
              ) : null}
              <div className="productPopupQuantity">
                <div className="p-grid">
                  <div className="p-col-6 p-offset-3">
                    <InputNumber
                      max={10}
                      style={{ marginBottom: "1em" }}
                      value={this.state.selectedQuantity}
                      onChange={(e) => {
                        if (e.value > 1) {
                          this.setState({ selectedQuantity: e.value });
                        } else {
                          this.setState({ selectedQuantity: 1 });
                        }
                      }}
                      showButtons
                      buttonLayout="horizontal"
                      decrementButtonClassName="p-button-danger"
                      incrementButtonClassName="p-button-success"
                      incrementButtonIcon="pi pi-plus"
                      decrementButtonIcon="pi pi-minus"
                    />
                  </div>
                </div>
              </div>

              {/* DISPLAY ADDONS */}
              {this.state.product.hasOwnProperty("addons") &&
                this.renderAddons()}

              <div className="p-d-flex selectionAdd">
                <Button
                  type="button addButton"
                  onClick={() => this.hideProductPopup(false)}
                  label="Cancel"
                  className="p-button-danger p-mr-2"
                  style={{ marginTop: 10 }}
                />

                <Button
                  disabled={this.checkDisabled()}
                  type="button addButton"
                  onClick={() => {
                    let overQuantity = this.addToCart();
                    if (!overQuantity)
                      UserStore.total =
                        UserStore.total + this.state.selectedQuantity;
                  }}
                  label={`Add (€${parseFloat(
                    (this.state.product.price + this.calculateExtrasPrice()) *
                      this.state.selectedQuantity
                  ).toFixed(2)})`}
                  className="p-button-success"
                  style={{ marginRight: ".25em", marginTop: 10 }}
                />
              </div>
            </div>
          )}
        </Sidebar>
      </div>
    );
  }
}

export default CanteenMenu;
