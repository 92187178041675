import React from "react";
import Header from "../components/Header";

import { callApi } from "../services/ApiService";
import { Link } from "react-router-dom";
import { displayPrice, cartPrice } from "../utilities/Utilities";
import { Accordion, AccordionTab } from "primereact/accordion";

import Moment from "moment";

class Orders extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      loading: true,
    };
  }

  componentDidMount() {
    this.getOrderHistory();
  }

  getOrderHistory = () => {
    callApi("getOrderHistory", "get", true)
      .then((data) => {
        if (typeof data == "undefined") {
          setTimeout(() => {
            this.getOrderHistory();
          }, 3000); // retry the call
        } else {
          this.setState({ data: data, loading: false }); // success
        }
      })
      .catch((e) => {
        this.setState({ loading: false });
      });
  };

  displayAddons(product) {
    if (product.hasOwnProperty("addons")) {
      return product.addons.map((addon) => {
        return (
          <div style={{ fontWeight: "normal" }}>
            {addon.name}{" "}
            {addon.price !== 0 ? `€${addon.price.toFixed(2)}` : null}
          </div>
        );
      });
    }
  }
  render() {
    return (
      <div>
        <Header />
        <h2 style={{ marginTop: 70 }} className="cartTitle">
          <Link to="/">
            <button
              style={{
                cursor: "pointer",
                borderStyle: "none",
              }}
              className="pi pi-angle-left cartButton"
            ></button>
          </Link>
          <span className="cartTitleSpan">Orders</span>
        </h2>
        <Accordion multiple={true}>
          {this.state.data.map((dataset) => {
            return (
              <AccordionTab
                key={dataset.id}
                header={
                  "Order ID: " +
                  dataset.id +
                  " " +
                  "Order Code: " +
                  dataset.code +
                  " "
                }
              >
                <p style={{ fontWeight: "bold" }}>
                  Date: {Moment(dataset.created_at).format("DD/MM/YYYY H:mm")}
                </p>
                <ul style={{ listStyleType: "none" }}>
                  {dataset.products.map((data) => {
                    return (
                      <li key={data.id}>
                        <span style={{ fontWeight: "bold" }}>
                          {data.pivot.quantity}x {data.label}{" "}
                          {displayPrice(data.price)}
                        </span>
                        <div style={{ marginLeft: 20 }}>
                          {this.displayAddons(data)}
                        </div>
                      </li>
                    );
                  })}
                </ul>
                <div>{dataset.total ? cartPrice(dataset.total) : null}</div>
              </AccordionTab>
            );
          })}
        </Accordion>
      </div>
    );
  }
}

export default Orders;
