import React from "react";
import UserStore from "../stores/UserStore";

export function displayPrice(price) {
  return <>€{price.toFixed(2)}</>;
}

export function calculateCartItemPrice(item, addons, quantity) {
  let cartItemPrice = item.price;
  addons.forEach((addon) => {
    cartItemPrice += addon.price;
  });
  cartItemPrice += cartItemPrice * quantity;
  return cartItemPrice;
}

export function quantityPrice(price, quantity) {
  let totalPrice = price * quantity;
  return <div>{displayPrice(totalPrice)}</div>;
}

export function cartPrice(price) {
  return <div>Total Price: €{price.toFixed(2)}</div>;
}

// export function footerCartPrice(price, quantity) {
//     return quantity + " items: " + "€ " + price.toFixed(2)
// }
export function footerCartPrice(price, quantity) {
  return "€ " + price.toFixed(2);
}

export function handleLabel(item) {
  if (item.price === 0) return <>{item.name}</>;
  else
    return (
      <>
        {item.name} +€{item.price.toFixed(2)}
      </>
    );
}

export function displaySelectedAddons(item) {
  var addonsMarkup = [];
  if (item.hasOwnProperty("addons")) {
    item.addons.forEach((addon) => {
      addonsMarkup.push(
        <div>
          {addon.addonName || addon.name}: {addon.optionName}
        </div>
      );
    });
    return addonsMarkup;
  }
}

export function displayYearOptions() {
  var currentYear = new Date().getFullYear();
  var years = [];
  for (var i = 0; i < 10; i++) {
    years.push(
      <option key={currentYear} value={currentYear}>
        {currentYear}
      </option>
    );
    currentYear++;
  }
  return years;
}

export const removeFromCart = (item) => {
  UserStore.total = 0;
  // filter out selected item
  let newCartObj = UserStore.data.cart.filter(function (obj) {
    return obj.cartItemId !== item.cartItemId;
  });
  // recalculate number of products in cart
  newCartObj.forEach((cartItem) => {
    UserStore.total = UserStore.total + cartItem.quantity;
  });
  UserStore.data.cart = newCartObj;
  localStorage.setItem("UserStore", JSON.stringify(UserStore));
};
