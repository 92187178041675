import React, { Fragment } from "react";
import Header from "../components/Header";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import {
  cartPrice,
  quantityPrice,
  removeFromCart,
  displaySelectedAddons,
} from "../utilities/Utilities";
import { observer } from "mobx-react";
import { Link } from "react-router-dom";
import { callApi } from "../services/ApiService";
import UserStore from "../stores/UserStore";
import CardsSelector from "../components/CardsSelector";
import SelectType from "../components/SelectType";
// import { ReactComponent } from '*.svg';

class Cart extends React.Component {
  constructor() {
    super();
    this.couponInputElement = React.createRef(null);
    this.priceElement = React.createRef(null);
    this.state = {
      loading: false,
      outOfStock: "",
      selectedCard: "",
      cardsSelector: false,
      oldPrice: UserStore.data.oldPrice || 0,
      price: UserStore.data.price || 0,
      discount_value: UserStore.data.discount_value || "",
      discount: UserStore.data.discount || false,
      couponCode: UserStore.data.couponCode || "",
      couponCodeError: "",
      couponUsed: UserStore.data.couponUsed || false,
    };
  }

  componentDidMount() {
    let calculate = 0;
    UserStore.data.cart.forEach((dataset) => {
      UserStore.total = UserStore.total + dataset.quantity;
      calculate = calculate + dataset.price * dataset.quantity;
    });
    this.setState({ price: calculate });
    UserStore.data.price = this.state.price;
    if (this.state.couponCode !== "") {
      this.original_price = calculate;
      this.checkCouponCode(UserStore.data.couponCode);
    }
  }

  handlePlus = (item) => {
    let calculate = 0;
    UserStore.data.cart.forEach((cartItem) => {
      if (item.cartItemId === cartItem.cartItemId && cartItem.quantity < 10) {
        cartItem.quantity++;
        UserStore.total++;
      }
      UserStore.total = UserStore.total + cartItem.quantity;
      calculate = calculate + cartItem.price * cartItem.quantity;
    });
    // localStorage.setItem("UserStore", JSON.stringify(UserStore));
    this.setState({ price: calculate });
    UserStore.data.price = this.state.price;
    if (this.state.couponCode) {
      this.original_price = calculate;
      this.checkCouponCode(this.state.couponCode);
    }
  };

  handleMinus = (item) => {
    let calculate = 0;
    UserStore.data.cart.forEach((cartItem) => {
      if (item.cartItemId === cartItem.cartItemId && cartItem.quantity > 1) {
        cartItem.quantity--;
        UserStore.total--;
      }
      UserStore.total = UserStore.total + cartItem.quantity;
      calculate = calculate + cartItem.price * cartItem.quantity;
    });
    // localStorage.setItem("UserStore", JSON.stringify(UserStore));
    this.setState({ price: calculate });
    UserStore.data.price = this.state.price;
    if (this.state.couponCode) {
      this.original_price = calculate;
      this.checkCouponCode(this.state.couponCode);
    }
  };

  onChangeCard = (card) => {
    this.setState({ selectedCard: card });
  };

  checkCouponCode = (couponCode) => {
    this.setState({ loading: true });
    callApi("checkCouponCode", "post", true, [
      {
        data: JSON.stringify({
          code: couponCode,
          merchant_id: UserStore.data.merchant_id,
          cart_value: this.original_price || this.state.price,
        }),
      },
    ])
      .then((response) => {
        if (typeof response == "object") {
          if (
            response.max_redemptions === 0 ||
            response.redemptions < response.max_redemptions
          ) {
            const currentDate = new Date().getTime();
            const startDate = new Date(response.start_date).getTime();
            const endDate = new Date(response.end_date).getTime();
            if (currentDate <= endDate && currentDate >= startDate) {
              if (response.type === "fixed_amount") {
                this.setState(
                  { discount_value: response.discount_value },
                  () => {
                    UserStore.data.discount_value = this.state.discount_value;
                  }
                );
                const oldPrice = this.state.oldPrice;
                const newPrice = oldPrice - response.discount_value;
                this.setState(
                  {
                    couponCodeError: "",
                    couponCode: couponCode,
                    oldPrice: oldPrice,
                    price: newPrice,
                    couponUsed: true,
                  },
                  () => {
                    UserStore.data.couponCodeError = this.state.couponCodeError;
                    UserStore.data.couponCode = this.state.couponCode;
                    UserStore.data.oldPrice = this.state.oldPrice;
                    UserStore.data.price = this.state.price;
                    UserStore.data.couponUsed = this.state.couponUsed;
                  }
                );
              } else {
                const oldPrice = this.state.price;
                this.setState(
                  {
                    discount_value: oldPrice * (response.discount_value / 100),
                  },
                  () => {
                    UserStore.data.discount_value = this.state.discount_value;
                  }
                );
                const newPrice =
                  oldPrice - oldPrice * (response.discount_value / 100);
                this.setState(
                  {
                    couponCodeError: "",
                    couponCode: couponCode,
                    oldPrice: oldPrice,
                    price: newPrice,
                    couponUsed: true,
                  },
                  () => {
                    UserStore.data.couponCodeError = this.state.couponCodeError;
                    UserStore.data.couponCode = this.state.couponCode;
                    UserStore.data.oldPrice = this.state.oldPrice;
                    UserStore.data.price = this.state.price;
                    UserStore.data.couponUsed = this.state.couponUsed;
                  }
                );
              }
            } else {
              this.setState({
                couponCodeError: `This coupon is only valid between ${response.start_date} and ${response.end_date}`,
              });
            }
          } else {
            this.setState({
              couponCodeError: "The redemptions for this coupon have run out.",
            });
          }
        } else {
          this.setState({ couponCodeError: response });
          this.setState({ couponUsed: false });
          UserStore.data.couponUsed = this.state.couponUsed;
          this.setState({ couponCode: "" });
          UserStore.data.couponCode = this.state.couponCode;
          this.original_price = 0;
        }
        this.setState({ loading: false });
      })
      .catch((response) => {
        console.log("Coupon Code Check API call failure:", response);
        this.setState({
          couponCodeError: "A problem occured whilst checking the coupon code.",
        });
      });
  };

  prepareOrderData = () => {
    let preparedData = {};
    preparedData.canteenId = UserStore.data.canteen_id;
    preparedData.sectorId = UserStore.data.sector_id;
    preparedData.sectorName = UserStore.data.sector_name;
    preparedData.standName = UserStore.data.stand_name;
    // preparedData.orderType = UserStore.data.order_type;
    preparedData.frontendPrice = this.state.price.toFixed(2);
    preparedData.couponCode = this.state.couponCode;

    // console.log("PREPARED DATA: ", preparedData);

    let items = [];
    UserStore.data.cart.forEach((cartItem) => {
      let item = {};
      item.quantity = cartItem.quantity;
      item.id = cartItem.id;
      if (cartItem.hasOwnProperty("addons")) {
        let addons = [];
        cartItem.addons.forEach((cartAddon) => {
          let addon = cartAddon.optionId.toString();
          addons.push(addon);
          item.addons = addons;
        });
      }
      items.push(item);
    });
    preparedData.items = items;

    this.placeOrder(preparedData);
  };

  placeOrder = (preparedData) => {
    callApi("placeOrder", "post", true, [
      { data: JSON.stringify(preparedData) },
    ]).then((response) => {
      if (typeof response == "undefined") {
        // setTimeout(() => { this.placeOrder(); }, 3000); // retry the call
      } else {
        if (response.hasOwnProperty("error")) {
          var arrayList = response.error.map((txt) => <p>{txt}</p>);
          this.setState({ outOfStock: arrayList });
        } else {
          this.props.history.push({
            pathname: "/checkout",
            state: {
              orderId: response.orderId,
              total: response.total,
              card_token: this.state.selectedCard.cardToken || null,
            },
          });
        }
      }
    });
  };

  render() {
    let calculate = 0;
    return (
      <div style={{ overflowX: "hidden" }}>
        <Header />
        <h2 style={{ marginTop: 70 }} className="cartTitle">
          <Link to="/">
            <button
              style={{
                cursor: "pointer",
                borderStyle: "none",
              }}
              className="pi pi-angle-left cartButton"
            ></button>
          </Link>
          <span className="cartTitleSpan">Cart</span>
        </h2>
        <SelectType />
        {UserStore.data.cart.length > 0 ? (
          <div>
            <div
              style={{
                maxHeight: "35vh",
                overflowY: "auto",
                overflowX: "hidden",
              }}
            >
              {UserStore.data.cart.map((cartItem) => {
                if (cartItem.discount == 1) {
                  this.state.discount = true;
                }
                calculate = calculate + cartItem.price * cartItem.quantity;
                return (
                  <div
                    key={cartItem.label + cartItem.price}
                    style={{
                      marginLeft: "10px",
                      marginTop: "20px",
                      marginBottom: "10px",
                    }}
                    className="p-grid cartItem"
                  >
                    <div className="p-col-5">
                      <div
                        style={{ fontWeight: "bold" }}
                        className="cartItemName"
                      >
                        {cartItem.quantity}x {cartItem.label}{" "}
                      </div>
                      {displaySelectedAddons(cartItem)}
                    </div>
                    <div className="p-col-1" style={{ alignSelf: "center" }}>
                      {this.state.loading ? (
                        <Button
                          disabled
                          className="cartButtonAdd cartButton"
                          type="button"
                          icon="pi pi-spin pi-spinner"
                        ></Button>
                      ) : (
                        <Button
                          className="cartButtonAdd cartButton"
                          type="button"
                          icon="pi pi-minus"
                          onClick={() => this.handleMinus(cartItem)}
                        ></Button>
                      )}
                    </div>
                    <div className="p-col-1" style={{ alignSelf: "center" }}>
                      {this.state.loading ? (
                        <Button
                          disabled
                          className="cartButtonAdd cartButton quanButton"
                          type="button"
                          icon="pi pi-spin pi-spinner"
                        ></Button>
                      ) : (
                        <Button
                          className="cartButtonAdd cartButton quanButton"
                          type="button"
                          icon="pi pi-plus"
                          onClick={() => this.handlePlus(cartItem)}
                        ></Button>
                      )}
                    </div>
                    <div className="p-col-3">
                      <div
                        style={{ fontWeight: "bold" }}
                        className="cartItemPrice"
                      >
                        {quantityPrice(cartItem.price, cartItem.quantity)}
                      </div>
                    </div>
                    <div className="p-col-1">
                      <Button
                        style={{ marginRight: "15px", position: "relative" }}
                        className="cartButtonClose"
                        type="button"
                        icon="pi pi-times-circle"
                        onClick={() => {
                          removeFromCart(cartItem);
                          let calculate = 0;
                          UserStore.data.cart.forEach((dataset) => {
                            UserStore.total =
                              UserStore.total + dataset.quantity;
                            calculate =
                              calculate + dataset.price * dataset.quantity;
                          });
                          this.setState({ price: calculate });
                        }}
                      ></Button>
                    </div>
                  </div>
                );
              })}
            </div>
            {!this.state.couponUsed && this.state.discount == false && (
              <Fragment>
                <div
                  style={{
                    marginLeft: "15px",
                    fontWeight: "bold",
                    marginTop: "10px",
                  }}
                >
                  Coupon Code
                </div>
                <div style={{ display: "flex" }} className="coupon">
                  <input
                    style={{ marginLeft: "15px", height: "40px" }}
                    type="text"
                    ref={this.couponInputElement}
                  ></input>
                  <Button
                    style={{ height: "40px", width: "55px" }}
                    className="cartButtonAdd cartButton quanButton"
                    label={"Apply Coupon"}
                    onClick={() => {
                      this.state.oldPrice = this.state.price;
                      this.checkCouponCode(
                        this.couponInputElement.current.value
                      );
                    }}
                  />
                </div>
              </Fragment>
            )}
            {this.state.couponUsed && this.state.discount == false && (
              <div className="removeCoupon">
                <Button
                  style={{ height: "40px", width: "100px" }}
                  className="cartButtonAdd cartButton quanButton"
                  label={"Remove Coupon"}
                  onClick={() => {
                    this.setState(
                      {
                        couponCode: "",
                        couponCodeError: "",
                        couponUsed: false,
                        price: this.state.oldPrice,
                      },
                      () => {
                        UserStore.data.price = this.state.price;
                        UserStore.data.couponUsed = this.state.couponUsed;
                        UserStore.data.couponCode = this.state.couponCode;
                        UserStore.data.couponCodeError = this.state.couponCodeError;
                      }
                    );
                  }}
                />
              </div>
            )}
            {this.state.couponCodeError && (
              <div style={{ margin: "15px", color: "red" }}>
                {this.state.couponCodeError}
              </div>
            )}
            <div className="totalPrice"> </div>
            <div className="p-grid p-align-center">
              <div
                ref={this.priceElement}
                className="p-col-7"
                style={{ marginLeft: "15px", fontWeight: "bold" }}
              >
                {this.state.oldPrice !== 0 && this.state.couponUsed === true ? (
                  <div>
                    <span style={{ display: "block", color: "green" }}>
                      Coupon Applied!
                    </span>
                    Total Price:&nbsp;
                    <span
                      style={{ color: "red", textDecoration: "line-through" }}
                    >
                      €{this.state.oldPrice.toFixed(2)}
                    </span>
                    <span style={{ color: "green", marginLeft: "15px" }}>
                      €{this.state.price.toFixed(2)}
                    </span>
                  </div>
                ) : (
                  cartPrice(this.state.price)
                )}
              </div>
              <div className="p-col-5 ">
                <Dialog
                  closable
                  header="Error"
                  onHide={() => this.setState({ outOfStock: "" })}
                  visible={this.state.outOfStock === "" ? false : true}
                  style={{ width: "50vw" }}
                >
                  {this.state.outOfStock}
                </Dialog>
              </div>
            </div>

            {/* <CardsSelector
              deleteOption={false}
              selectedCard={this.state.selectedCard}
              onChangeCard={this.onChangeCard}
            /> */}
            <Button
              style={{
                borderStyle: "none",
                boxShadow: "0px -4px 4px rgba(0, 0, 0, 0.25)",
                fontSize: "20px",
                position: "fixed",
                bottom: 0,
                left: 0,
                width: "100%",
                color: "white",
                backgroundColor: "#178700",
                fontWeight: "bold",
              }}
              label={"Pay Now"}
              onClick={() => this.prepareOrderData()}
            />
          </div>
        ) : (
          <div>
            <h3 style={{ color: "#666666", textAlign: "center" }}>
              Your cart is empty
            </h3>
            <i
              style={{ fontSize: "4em" }}
              className="pi pi-shopping-cart shoppingCart"
            ></i>
          </div>
        )}
      </div>
    );
  }
}
export default observer(Cart);
