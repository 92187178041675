import React from "react";
import { Dialog } from "primereact/dialog";
import { callApi } from "../services/ApiService";
import UserStore from "../stores/UserStore";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
class ForgotPassword extends React.Component {
  constructor() {
    super();
    this.state = {
      privacy: false,
      forgot_email: "",
      errors: "",
      response_msg: "",
    };
  }

  ForgotPassword = () => {
    callApi("forgotPass", "post", false, [{ email: this.state.forgot_email }])
      .then((data) => {
        this.setState({ response_msg: data.msg });
        data.preventDefault();
        if (typeof data == "undefined") {
          UserStore.isLoggedIn = false;
          localStorage.clear();
        } else if (data.status === 422) {
          this.setState({ errors: data.data.errors });
        } else {
          UserStore.access_token = data.access_token;
          UserStore.name = data.name;
          localStorage.setItem("UserStore", JSON.stringify(UserStore));
          window.location.href = "/";
        }
      })
      .catch((err) => {});
  };

  handleFocus = (input) => {
    let errors = { ...this.state.errors };
    errors[input] = "";
    this.setState({ errors });
  };

  render() {
    const myIcon = (
      <button className="p-dialog-titlebar-icon p-link">
        <span className="pi pi-chevron-left"></span>
      </button>
    );

    return (
      <div>
        <Dialog
          style={{ width: "95vw" }}
          className="forgotPasswordDialog"
          iconsTemplate={this.state.privacy ? myIcon : null}
          closable
          onHide={() => this.props.hideForgotPasswordModal()}
          visible={this.props.forgotPasswordModal}
        >
          <div>
            <div className="forgotPasswordHeader" style={{ display: "flex" }}>
              <h2>Forgot Password</h2>
            </div>
            <h3 className="response_message">{this.state.response_msg}</h3>
            <div className="p-as-center p-mb-3">
              <InputText
                onFocus={() => this.handleFocus("email")}
                placeholder={"Email *"}
                className={
                  `txt-input ` +
                  (this.state.errors.email ? "invalid-input" : null)
                }
                value={this.state.forgot_email}
                onChange={(e) =>
                  this.setState({ forgot_email: e.target.value })
                }
                feedback={false}
              />
              {this.state.errors && this.state.errors.email ? (
                <span style={{ color: "red" }}>{this.state.errors.email}</span>
              ) : null}
            </div>
            <Button
              disabled={!this.state.forgot_email}
              onClick={this.ForgotPassword}
              style={{
                backgroundColor: "#178700",
                border: "1px solid #178700",
                width: "100%",
              }}
              label={"Reset Password "}
            />
          </div>
        </Dialog>
      </div>
    );
  }
}

export default ForgotPassword;
