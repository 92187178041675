import React from "react";

import { Button } from "primereact/button";
// import { Dialog } from 'primereact/dialog';
import { Dropdown } from "primereact/dropdown";
// import { Card } from 'primereact/card';
// import {InputMask} from 'primereact/inputmask';
import "primereact/resources/themes/nova-light/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import "primeflex/primeflex.css";
// import {Checkbox} from 'primereact/checkbox';
import { callApi } from "../services/ApiService";
import Header from "../components/Header";
import UserStore from "../stores/UserStore";

class MerchantSelectForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: "",
      data: [],
      loading: true,
      merchant: 0,
      merchantName: "",
      remember: false,
      telephone: UserStore.data.telephone || "",
      step: this.props.step,
    };
  }

  componentDidMount() {
    this.getMerchants();
  }

  getMerchants = () => {
    callApi("getMerchants", "get", true)
      .then((data) => {
        if (typeof data == "undefined") {
          setTimeout(() => {
            this.getMerchants();
          }, 3000); // retry the call
        } else {
          this.setState({ data: data, loading: false }); // success
        }
      })
      .catch((e) => {
        // console.log(e);
        this.setState({ loading: false });
      });
  };

  merchantsDropdown = () => {
    let merchantSelectItems = [];
    this.state.data.forEach((merchant) => {
      merchantSelectItems.push({ label: merchant.name, value: merchant.id });
    });
    return (
      <div className="p-field">
        {/* <label htmlFor="merchantList">Merchant</label> */}
        <Dropdown
          id="merchantList"
          value={this.state.merchant}
          options={merchantSelectItems}
          onChange={(e) => {
            this.merchantSelected(e);
          }}
          placeholder="Select Store"
        />
      </div>
    );
  };

  merchantSelected = (e) => {
    let selectedMerchant = this.state.data.find((obj) => {
      return obj.id === e.value;
    });
    this.setState({
      merchant: e.value,
      merchantName: selectedMerchant.name,
      error: "",
    });
  };

  submitMerchant = () => {
    if (this.state.merchant > 0) {
      UserStore.data.merchant_id = this.state.merchant;
      UserStore.data.merchant_name = this.state.merchantName;
      UserStore.data.cart = []; // empty the cart on merchant change
      UserStore.data.telephone = this.state.telephone;
      localStorage.setItem("UserStore", JSON.stringify(UserStore));
    } else {
      this.setState({ error: "Please select merchant" });
    }
  };

  onRememberChange = () => {
    this.setState({ remember: !this.state.remember });
  };
  render() {
    return (
      <div>
        <Header />
        <div
          className="sectorHeader"
          style={{ display: "flex", marginTop: 70 }}
        >
          <div className="circle">
            <img alt="location" src={require("../images/location.png")} />
          </div>
          <h2>Select Store</h2>
        </div>
        <div style={{ margin: 40 }}>
          <div className="p-fluid">
            {this.merchantsDropdown()}
            <div style={{ marginBottom: 15, color: "red" }}>
              {this.state.error}
            </div>
            <Button
              label="Επιβεβαίωση / Confirm"
              className="p-button-success sectorButton"
              onClick={() => this.submitMerchant()}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default MerchantSelectForm;
