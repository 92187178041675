import React from "react";
import { observer } from "mobx-react";

import UserStore from "../stores/UserStore";

// import { Button } from 'primereact/button';

import "primereact/resources/themes/nova-light/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";

import { callApi } from "../services/ApiService";
// import Header from '../components/Header';
// import Auth from '../services/Auth';

class Logout extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      loading: true,
    };
  }

  componentDidMount() {
    this.submitLogout();
  }

  submitLogout = () => {
    callApi("logout", "post", true)
      .then((data) => {
        localStorage.removeItem("UserStore");
        window.location.href = "/login";
      })
      .catch((e) => {
        // this.setState({ loading: false });
      });
  };

  render() {
    return <div>Logging Out {UserStore.name}</div>;
  }
}

export default observer(Logout);
