import React from "react";

import Header from "../components/Header";
import { Link } from "react-router-dom";
import CardsSelector from "../components/CardsSelector";
class Cards extends React.Component {
  render() {
    return (
      <>
        <Header />
        <h2 style={{ marginTop: 70 }} className="cartTitle">
          <Link to="/">
            <button
              style={{
                cursor: "pointer",
                borderStyle: "none",
              }}
              className="pi pi-angle-left cartButton"
            ></button>
          </Link>
          <span className="cartTitleSpan">Credit Cards</span>
        </h2>
        <CardsSelector deleteOption={true} />
      </>
    );
  }
}

export default Cards;
