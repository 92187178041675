import { extendObservable } from "mobx";

class UserStore {
  constructor() {
    extendObservable(this, {
      isLoggedIn: false,
      logged_in_at: "",
      access_token: "",
      name: "",
      oldPrice:0,
      price:0,
      discount_value: "",
      discount: false,
      couponCode:"",
      couponUsed:false,
      data: {
        canteen_id: "",
        sector_id: "",
        stand_id: "",
        sector_name: "",
        stand_name: "",
        merchant_id: "",
        merchant_name: "",
        order_type: "",
        cart: [],
        discount: false,
        telephone: "",
        canteen_name: "",
        orderId: 0,
      },
      total: 0,
    });
  }
}

export default new UserStore();
