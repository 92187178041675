import React from "react";
import Header from "../components/Header";
import { Redirect } from "react-router-dom";
import { Button } from "primereact/button";
import UserStore from "../stores/UserStore";
import { observer } from "mobx-react";

class PaymentSuccess extends React.Component {
  render() {
    const queryParams = new URLSearchParams(window.location.search)
    const response = JSON.parse(queryParams.get('response'));
    return (
      <div>
        <Header />
        <h2 className={"cartTitle"}>Payment Status</h2>

        <div>
          <div className="p-col-12 p-d-flex p-jc-center">
            <span
              style={{
                borderBottom: "3px solid #FFD600",
                display: "inline-flex",
              }}
            >
              <i className="pi pi-check p-as-center success-arrow" />
              {response.ResponseCode === "1" ?
              <h4>Order Successful</h4> :
              <div className="order-status">
                <h4 className="order-failed">Order Failed</h4>{"\n"}
                <p className="order-failed">Reason: {response.ReasonCodeDesc}</p>
                <p className="order-failed">Reason Code: {response.ReasonCode}</p>
              </div>
              } 
            </span>
          </div>
          <div className="p-col-12 p-jc-center">
            <h4 className="p-text-center">
              Αριθμός Παραγγελίας / Order Number
            </h4>
            <div style={{ margin: "0 auto", width: "50%" }}>
              <p
                className="p-text-center"
                style={{
                  fontWeight: "bold",
                  color: "black",
                  padding: 15,
                  backgroundColor: "#FFD600",
                  borderRadius: 5,
                }}
              >
                {response.OrderID}
              </p>
            </div>
          </div>
          <div className="p-col-12 p-jc-center" style={{marginBottom: "100px"}}>
            <h4 className="p-text-center">
              <i className="pi pi-map-marker p-mr-2" />
              Σημείο Παραλαβής / Pick Up Point
            </h4>
            <div style={{ margin: "0 auto", width: "50%" }}>
              <p className="p-text-center">{UserStore.data.canteen_name}</p>
              <p className="p-text-center">{UserStore.data.stand_name}</p>
            </div>
          </div>
        </div>
        <Button
          label={"Return to home"}
          onClick={(e) => {
            this.props.history.push("/");
            UserStore.data.cart = [];
          }}
          style={{
            borderStyle: "none",
            boxShadow: "0px -4px 4px rgba(0, 0, 0, 0.25)",
            position: "fixed",
            bottom: 0,
            fontSize: "20px",
            width: "100%",
            color: "white",
            backgroundColor: "#178700",
            fontWeight: "bold",
          }}
        />
      </div>
    );
  }
}

export default observer(PaymentSuccess);
