import React from "react";
import { observer } from "mobx-react";

import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { Link } from "react-router-dom";

import "primereact/resources/themes/nova-light/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";

import UserStore from "../stores/UserStore";
import {
  quantityPrice,
  footerCartPrice,
  cartPrice,
  removeFromCart,
  displaySelectedAddons,
} from "../utilities/Utilities";

const footer = (
  <Link to="/cart">
    <Button
      label="Checkout"
      style={{ color: "#1B1B1B", backgroundColor: "#FFD600", fontSize: "14px" }}
      className="p-button-success"
    />
  </Link>
);

class CartButton extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      cartVisible: false,
    };
  }

  componentDidMount() {
    UserStore.data.cart.forEach((dataset) => {
      UserStore.total = UserStore.total + dataset.quantity;
    });
  }

  handlePlus = (item) => {
    UserStore.data.cart.forEach((cartItem) => {
      if (item.cartItemId === cartItem.cartItemId && cartItem.quantity < 10) {
        cartItem.quantity++;
        UserStore.total++;
      }
    });
    localStorage.setItem("UserStore", JSON.stringify(UserStore));
  };

  handleMinus = (item) => {
    UserStore.data.cart.forEach((cartItem) => {
      if (item.cartItemId === cartItem.cartItemId && cartItem.quantity > 1) {
        cartItem.quantity--;
        UserStore.total--;
      }
    });
    localStorage.setItem("UserStore", JSON.stringify(UserStore));
  };

  render() {
    let calculate = 0;

    return (
      <div>
        <Dialog
          header="Shopping Cart"
          footer={footer}
          visible={this.state.cartVisible}
          style={{ margin: "2%", width: "96%", height: "79%" }}
          modal={true}
          position="bottom"
          onHide={() => this.setState({ cartVisible: false })}
        >
          {UserStore.total} items in cart.
          {UserStore.data.cart.map((cartItem) => {
            calculate = calculate + cartItem.price * cartItem.quantity;
            return (
              <div
                key={cartItem.label + cartItem.price}
                className="p-grid cartItem"
                style={{ marginTop: "10px", marginBottom: "10px" }}
              >
                <div className="p-col-5 cartItem">
                  <div style={{ fontWeight: "bold" }} className="cartItemName">
                    {cartItem.quantity}x {cartItem.label}{" "}
                  </div>
                  {displaySelectedAddons(cartItem)}
                </div>
                <div className="p-col-1" style={{ alignSelf: "center" }}>
                  <Button
                    className="cartButtonAdd cartButton"
                    type="button"
                    icon="pi pi-minus"
                    onClick={() => this.handleMinus(cartItem)}
                  ></Button>
                </div>
                <div className="p-col-1" style={{ alignSelf: "center" }}>
                  <Button
                    className="cartButtonAdd cartButton quanButton"
                    type="button"
                    icon="pi pi-plus"
                    onClick={() => this.handlePlus(cartItem)}
                  ></Button>
                </div>
                <div className="p-col-3" style={{ alignSelf: "center" }}>
                  <div className="cartItemPrice" style={{ fontWeight: "bold" }}>
                    {quantityPrice(cartItem.price, cartItem.quantity)}
                  </div>
                </div>
                <div className="p-col-1">
                  <Button
                    style={{ alignSelf: "center" }}
                    className="cartButtonClose"
                    type="button"
                    icon="pi pi-times-circle"
                    onClick={() => removeFromCart(cartItem)}
                  ></Button>
                </div>
              </div>
            );
          })}
          <div className="totalPriceCart" style={{ fontWeight: "bold" }}>
            {cartPrice(calculate)}
          </div>
        </Dialog>
        <div style={{ paddingTop: "20px" }}>
          {/* label={footerCartPrice(calculate, UserStore.total)} */}
          {/* <Button className="cart-button" style={{ borderStyle: "none", boxShadow: "0px -4px 4px rgba(0, 0, 0, 0.25)", fontSize:'20px', position: 'fixed', bottom: 0, left: 0,  width: '100%', color: "#1B1B1B", backgroundColor: "#FFD600", fontWeight: "bold"}} label={footerCartPrice(calculate, UserStore.total)} icon="pi pi-shopping-cart" onClick={(e) => this.setState({ cartVisible: true })} /> */}
          <Link to="/cart">
            <Button
              className="cart-button"
              style={{
                borderStyle: "none",
                boxShadow: "0px -4px 4px rgba(0, 0, 0, 0.25)",
                fontSize: "20px",
                position: "fixed",
                bottom: 0,
                left: 0,
                width: "100%",
                color: "#1B1B1B",
                backgroundColor: "#FFD600",
                fontWeight: "bold",
              }}
              label={footerCartPrice(calculate, UserStore.total)}
              icon="pi pi-shopping-cart"
            />
          </Link>
        </div>
      </div>
    );
  }
}

export default observer(CartButton);
