import React from "react";
import { observer } from "mobx-react";

// import { Button } from 'primereact/button';
// import { Dialog } from 'primereact/dialog';

import "primereact/resources/themes/nova-light/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";

import UserStore from "../stores/UserStore";
import Header from "../components/Header";
import SectorSelectForm from "../components/SectorSelectForm";
import MerchantSelectForm from "../components/MerchantSelectForm";
import CanteenMenu from "../components/CanteenMenu";
import CartButton from "../components/CartButton";

class HomeNew extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  resetSector = () => {
    UserStore.data.sector_id = 0;
    UserStore.data.sector_name = "";
    UserStore.data.stand_name = "";
    UserStore.data.telephone = "";
    UserStore.data.couponCode = "";
    UserStore.data.couponUsed = false;
    localStorage.removeItem("UserPosition");
  };

  render() {
    return (
      <div>
        <Header />

        {UserStore.data.merchant_id === "" &&
          UserStore.data.merchant_name === "" && <MerchantSelectForm />}

        {UserStore.data.merchant_id > 0 &&
          UserStore.data.merchant_name !== "" &&
          UserStore.data.sector_name === "" &&
          UserStore.data.stand_name === "" && 
          UserStore.data.telephone === "" && <SectorSelectForm />}

        {UserStore.data.sector_id > 0 &&
          UserStore.data.sector_name !== "" &&
          UserStore.data.stand_id > 0 &&
          UserStore.data.merchant_id !== "" &&
          UserStore.data.merchant_name !== "" &&
          UserStore.data.telephone !== "" && (
            <div style={{ margin: 15, marginTop: 70 }}>
              <CanteenMenu resetSector={this.resetSector} />
              <CartButton />
            </div>
          )}
      </div>
    );
  }
}

export default observer(HomeNew);
