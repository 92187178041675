import React from "react";
import { observer } from "mobx-react";
import Header from "../components/Header";
import { ProgressSpinner } from "primereact/progressspinner";
import { Button } from "primereact/button";
import { Checkbox } from "primereact/checkbox";
import { Link } from "react-router-dom";
import { displayYearOptions } from "../utilities/Utilities";
import { callApi } from "../services/ApiService";
import UserStore from "../stores/UserStore";
import { Redirect } from "react-router-dom";
import { Dialog } from "primereact/dialog";
// import axios from 'axios';
const months = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];

class Checkout extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      disableBtn: true,
      vivaPopup: 0,
      authToken: "",
      paymentLoading: 0,
      paymentRejected: false,
      rememberCard: false,
      viva_token: "",
      card: "",
      name: "",
      month: "",
      year: "",
      cvv: "",
      errorMsg: "There was a problem with the payment, please try again or use a different card.",
      jccVariables: {}
    };
    this.handleChange = this.handleChange.bind(this);
    this.submitButtonRef = React.createRef();
  }

  handleChange(event) {
    this.setState({value: event.target.value});
  }

  componentDidMount() {
    if (
      !this.props.history.location.state ||
      typeof this.props.history.location.state === "undefined"
    ) {
      this.props.history.push("/");
    } // else this.getAccessToken();
    else {
      // console.log(this.props.location.state.total.toFixed(2));
      this.jccPayment();
    }
    UserStore.data.orderId = this.props.location.state.orderId;
  }

  jccPayment = () => {
    // console.log("kalispera state", this.props.history.location.state.orderId);
    callApi(
      `jcc/jccVariables`,
      "post",
      true,
      [{new: "1"}, {env: "prod"}, {purchaseAmt: this.props.location.state.total.toFixed(2)}, {orderId: this.props.location.state.orderId}, {hashToken: ""}]
    ).then((res) => {
      this.setState({jccVariables: res});
      this.submitButtonRef.click();
    });
  };

  removeOrderAPI = () => {
    callApi("removeOrderAPI", "post", true, [
      { orderId: UserStore.data.orderId },
    ])
      .then((data) => {
        console.log(data);
        this.props.history.push("/cart");
      })
      .catch((e) => {
        this.setState({ loading: false });
      });
  };

  executeTransaction = (params) => {
    callApi("create-transaction", "post", true, [
      { data: JSON.stringify(params) },
    ])
      .then((res) => {
        if (res.status === "success") {
          this.setState({ paymentLoading: 0 });
          UserStore.data.cart = [];

          this.props.history.push({
            pathname: "/payment-success",
            state: { orderId: res.orderId },
          });
        } else {
          this.setState({
            paymentLoading: 0,
            paymentRejected: true,
            vivaPopup: 0,
          });
        }
      })
      .catch((err) => {
        this.setState({ paymentLoading: 0 });
      });
  };

  render() {
    return (
      <div style={{backgroundColor:"orange", height:"100vh"}}>
        <div style={{paddingTop: "100px", margin: "auto", textAlign:"center"}}>
          <img alt="loading-jcc" style={{width: "100px"}} src={require("../images/oval.svg")} />
          <h3 style={{color: "white"}}>Redirecting to JCC...</h3>
        </div>
        <form method="post" name="paymentForm" id="paymentForm" action={this.state.jccVariables.apiUrl}>
          <input type="hidden" name="Version" value={this.state.jccVariables.Version || ""}  onChange={this.handleChange}/>
          <input type="hidden" name="MerID" value={this.state.jccVariables.MerID || ""} onChange={this.handleChange}/>
          <input type="hidden" name="AcqID" value={this.state.jccVariables.AcqID || ""} onChange={this.handleChange}/>
          <input type="hidden" name="MerRespURL" value={this.state.jccVariables.MerRespURL || ""} onChange={this.handleChange}/>
          <input type="hidden" name="MerCallbackURL" value={this.state.jccVariables.MerCallbackURL || ""} onChange={this.handleChange}/>
          <input type="hidden" name="PurchaseAmt" value={this.state.jccVariables.PurchaseAmt || ""} onChange={this.handleChange}/>
          <input type="hidden" name="PurchaseCurrency" value={this.state.jccVariables.PurchaseCurrency || ""} onChange={this.handleChange}/>
          <input type="hidden" name="PurchaseCurrencyExponent" value={this.state.jccVariables.PurchaseCurrencyExponent || ""} onChange={this.handleChange}/>
          <input type="hidden" name="OrderID" value={this.state.jccVariables.OrderID || ""} onChange={this.handleChange}/>
          <input type="hidden" name="CaptureFlag" value={this.state.jccVariables.CaptureFlag || ""} onChange={this.handleChange}/>
          <input type="hidden" name="COFFlag" value={this.state.jccVariables.COFFlag || ""} onChange={this.handleChange}/>
          <input type="hidden" name="Signature" value={this.state.jccVariables.Signature || ""} onChange={this.handleChange}/>
          <input type="hidden" name="SignatureMethod" value={this.state.jccVariables.SignatureMethod || ""} onChange={this.handleChange}/>
          <input type="hidden" name="trxType" value={this.state.jccVariables.trxType || ""} onChange={this.handleChange}/>
          <input type="hidden" name="hashValue" value={this.state.jccVariables.hashValue || ""} onChange={this.handleChange}/>
          <input ref={submit => this.submitButtonRef = submit} type="submit" name="submitPaymentButton" value="Pay" style={{display:"none"}}/>
        </form>
      </div>
    )}
}

export default observer(Checkout);
