import React from "react";

const PrivacyPolicyComponent = () => {
  return (
    <div style={{ marginRight: "0.5em", marginLeft: "0.5em" }}>
      <p>
        <span style={{ fontWeight: 400 }}>Terms and Conditions</span>
      </p>
      <p>
        <span style={{ fontWeight: 400 }}>
          Welcome to www.kantinapp.com.cy (the "Website") and
          &ldquo;kantinapp&rdquo; application for mobile and handheld devices
          (the &ldquo;App&rdquo;). The Website and App are jointly referred to
          as the &ldquo;Services&rdquo; provided.
        </span>
      </p>
      <p>
        <em>
          <span style={{ fontWeight: 400 }}>Kantinapp </span>
        </em>
        <span style={{ fontWeight: 400 }}>
          is an ordering service provider offering an online platform where
          users of the Website and/or App may order food online from the list of
          locations and vendors available on the Services
          (&ldquo;Vendors&rdquo;); and pre-pay for their food order by banking
          channels made available to the on the Service.
        </span>
      </p>
      <p>
        <span style={{ fontWeight: 400 }}>
          We do not own, sell, resell, furnish, provide, prepare, manage and/or
          control the Vendors or the related services provided in connection
          thereof.&nbsp;
        </span>
      </p>
      <p>
        <span style={{ fontWeight: 400 }}>
          Our responsibilities are limited to: (i) facilitating the availability
          of the Services; and (ii) serving as the limited agent of each Vendor
          for the purpose of accepting payments from you for your online food
          order.&nbsp;
        </span>
      </p>
      <p>
        <span style={{ fontWeight: 400 }}>
          The Services are owned by PLANET CARAVAN SOFTWARE LTD, a limited
          liability company registered in the Republic of Cyprus with
          registration number H413204 and registered address at 8 Michalaki
          Karaoli Street, Anemomylos Building, 4
        </span>
        <span style={{ fontWeight: 400 }}>rd </span>
        <span style={{ fontWeight: 400 }}>floor, 1095 Nicosia, Cyprus.</span>
      </p>
      <p>
        <span style={{ fontWeight: 400 }}>
          These Terms and Conditions ("Agreement") are a legal agreement that
          explain the terms and conditions that all visitors, users, clients and
          customers ("You", or "Your") must comply with when using the Website
          and the App. By installing, downloading or otherwise using the Website
          and App, You agree to abide by all of the terms and conditions in this
          Agreement.
        </span>
      </p>
      <p>
        <span style={{ fontWeight: 400 }}>
          This Agreements incorporates Our{" "}
        </span>
        <a href="http://kantinapp.com.cy/privacypolicy">
          <span style={{ fontWeight: 400 }}>Privacy Policy</span>
        </a>
        <span style={{ fontWeight: 400 }}>.&nbsp;</span>
      </p>
      <p>
        <span style={{ fontWeight: 400 }}>
          These Terms and Conditions outline the rules and regulations for the
          use of kantinapp.com.cy.{" "}
        </span>
        <strong>
          PLEASE READ THIS AGREEMENT CAREFULLY because it affects Your rights
          and liabilities under the law. By using or accessing the Website, You
          confirm that You have read and agreed to be bound by this Agreement.
          If You do not agree with this Agreement, You cannot use or access the
          Website.
        </strong>
        <span style={{ fontWeight: 400 }}>&nbsp;</span>
      </p>
      <p>
        <span style={{ fontWeight: 400 }}>
          These Terms and conditions are governed by the law of Cyprus
        </span>
      </p>
      <ol>
        <li style={{ fontWeight: 400 }}>
          <h2>
            <span style={{ fontWeight: 400 }}>Definitions</span>
          </h2>
        </li>
      </ol>
      <p>
        <span style={{ fontWeight: 400 }}>
          The following terminology applies to these Terms and Conditions,
          Privacy Statement and Disclaimer Notice and any or all agreements:
        </span>
      </p>
      <p>
        <span style={{ fontWeight: 400 }}>"</span>
        <strong>Company</strong>
        <span style={{ fontWeight: 400 }}>," "</span>
        <strong>Us</strong>
        <span style={{ fontWeight: 400 }}>," "</span>
        <strong>We</strong>
        <span style={{ fontWeight: 400 }}>," and "</span>
        <strong>Our</strong>
        <span style={{ fontWeight: 400 }}>
          " refers to Planet Caravan Software Ltd.
        </span>
      </p>
      <p>
        <span style={{ fontWeight: 400 }}>"</span>
        <strong>Content</strong>
        <span style={{ fontWeight: 400 }}>
          " is the information displayed at the Website including without
          limitation text, data, code, images, photographs, graphics, software,
          designs and any other materials that are available at the Website.
        </span>
      </p>
      <p>
        <span style={{ fontWeight: 400 }}>"</span>
        <strong>Services</strong>
        <span style={{ fontWeight: 400 }}>
          " refers to the ordering and delivering services provided by the
          Website and App.
        </span>
      </p>
      <p>
        <span style={{ fontWeight: 400 }}>"</span>
        <strong>You</strong>
        <span style={{ fontWeight: 400 }}>" and "</span>
        <strong>Your</strong>
        <span style={{ fontWeight: 400 }}>
          " refer to the visitor, user, client, customer or person accessing the
          Website and thus accepting this Agreement.
        </span>
      </p>
      <ol>
        <li style={{ fontWeight: 400 }}>
          <h2>
            <span style={{ fontWeight: 400 }}>
              User Rights and Responsibility
            </span>
          </h2>
        </li>
      </ol>
      <h3>
        <span style={{ fontWeight: 400 }}>2.1 Acceptable use</span>
      </h3>
      <ul>
        <li style={{ fontWeight: 400 }}>
          <span style={{ fontWeight: 400 }}>
            The Company will not be held liable should the website be
            unavailable for any amount of time.
          </span>
        </li>
        <li style={{ fontWeight: 400 }}>
          <span style={{ fontWeight: 400 }}>
            You are responsible for the safety and confidentiality of your
            passwords and usernames. The Company has the right to terminate or
            suspend your username and password should we suspect non-compliance
            by you.
          </span>
        </li>
        <li style={{ fontWeight: 400 }}>
          <span style={{ fontWeight: 400 }}>
            You will not use any meta tags or any other "hidden text" utilizing
            Our name or trademarks without Our express written consent.
          </span>
        </li>
        <li style={{ fontWeight: 400 }}>
          <span style={{ fontWeight: 400 }}>
            The Website and/or any portion of the Website may not be reproduced,
            sold, resold, visited or otherwise exploited for any commercial
            purpose without Our express written consent.
          </span>
        </li>
        <li style={{ fontWeight: 400 }}>
          <span style={{ fontWeight: 400 }}>
            Your use and access of the Website does not include any right of
            resale or commercial use of the Website or its Contents; any
            collection and use of any product listings, descriptions, or prices;
            any derivative use of the Website or its Contents; any downloading
            or copying of account information for the benefit of another
            merchant; or any use of data mining, robots, or similar data
            gathering and extraction tools.
          </span>
        </li>
        <li style={{ fontWeight: 400 }}>
          <span style={{ fontWeight: 400 }}>
            You must use our website for lawful purposes only.
          </span>
        </li>
      </ul>
      <h3>
        <span style={{ fontWeight: 400 }}>2.2 Prohibited Use</span>
      </h3>
      <p>
        <span style={{ fontWeight: 400 }}>
          While using our service the following terms must be adhered to:
        </span>
      </p>
      <ul>
        <li style={{ fontWeight: 400 }}>
          <span style={{ fontWeight: 400 }}>
            to transmit or send unsolicited or unauthorized advertising or
            promotional material in any form.
          </span>
        </li>
        <li style={{ fontWeight: 400 }}>
          <span style={{ fontWeight: 400 }}>
            Disrupt or attempt to disrupt Our servers in any way that could
            cause harm to the Website.
          </span>
        </li>
        <li style={{ fontWeight: 400 }}>
          <span style={{ fontWeight: 400 }}>
            You shall not use any "deep-link", "page-scrape", "robot", "spider"
            or other automatic device, program, algorithm or methodology, or any
            similar or equivalent manual process, to access, acquire, copy or
            monitor any portion of the Website or any Content, or in any way
            reproduce or circumvent the navigational structure or presentation
            of the Website or any Content, to obtain or attempt to obtain any
            materials, documents or information through any means not purposely
            made available through the Website. We reserve Our right to bar any
            such activity.
          </span>
          <span style={{ fontWeight: 400 }}>&nbsp;</span>
        </li>
        <li style={{ fontWeight: 400 }}>
          <span style={{ fontWeight: 400 }}>
            Distribute any material that contains software viruses or any other
            computer code, files, or programs that are designed or intended to:
            (a) disrupt, damage, or limit the functioning of the Website, any
            software, hardware, or telecommunications equipment used in
            connection therewith; or (b) damage or obtain unauthorized access to
            any data or other information of any third party.&nbsp;
          </span>
        </li>
        <li style={{ fontWeight: 400 }}>
          <span style={{ fontWeight: 400 }}>
            in any way that breaks or breaches applicable local, national or
            international laws or regulations.&nbsp;
          </span>
        </li>
        <li style={{ fontWeight: 400 }}>
          <span style={{ fontWeight: 400 }}>
            in any way which is fraudulent or unlawful.&nbsp;
          </span>
        </li>
        <li style={{ fontWeight: 400 }}>
          <span style={{ fontWeight: 400 }}>
            Hold Yourself out as sponsored by, endorsed by, or affiliated with
            Us or the Website.
          </span>
        </li>
        <li style={{ fontWeight: 400 }}>
          <span style={{ fontWeight: 400 }}>
            Interfere with any other person's use of or the proper functioning
            of the Website.&nbsp;
          </span>
        </li>
        <li style={{ fontWeight: 400 }}>
          <span style={{ fontWeight: 400 }}>
            Misrepresent Your identity or impersonate any person.
          </span>
        </li>
        <li style={{ fontWeight: 400 }}>
          <span style={{ fontWeight: 400 }}>
            Modify, translate, adapt, edit, decompile, disassemble, or reverse
            engineer any software programs used by Us.
          </span>
        </li>
        <li style={{ fontWeight: 400 }}>
          <span style={{ fontWeight: 400 }}>
            Provide any information that is false, misleading or inaccurate.
          </span>
        </li>
        <li style={{ fontWeight: 400 }}>
          <span style={{ fontWeight: 400 }}>
            Use any portion of the Website for any unlawful purpose or otherwise
            fail to comply with all applicable domestic and international laws,
            statutes, ordinances and regulations.
          </span>
        </li>
      </ul>
      <p>&nbsp;</p>
      <ul>
        <ol>
          <li style={{ fontWeight: 400 }}>
            <h2>
              <span style={{ fontWeight: 400 }}>Our Content</span>
            </h2>
          </li>
        </ol>
      </ul>
      <p>
        <span style={{ fontWeight: 400 }}>
          Except for User Content as defined in User Content clause below, all
          of the content featured or displayed on the Website, including,
          without limitation, text, graphics, photographs, images, moving
          images, sound, and illustrations ("Our Content"), are owned by Us, Our
          licensors, vendors, agents and/or Our content providers. All elements
          of the Website, including without limitation the general design and
          Our Content, are protected by trade dress, copyright, moral rights,
          trademark and other laws relating to intellectual property rights.
        </span>
      </p>
      <p>
        <span style={{ fontWeight: 400 }}>
          The Website, Our Content and all related rights shall remain Our
          exclusive property or of Our licensors unless otherwise expressly
          agreed.
        </span>
      </p>
      <p>
        <span style={{ fontWeight: 400 }}>
          You will not remove any copyright, trademark or other proprietary
          notices from material found on the Website.
        </span>
      </p>
      <p>
        <span style={{ fontWeight: 400 }}>
          Except as may be otherwise indicated in specific documents within the
          Website or as permitted by copyright law, You are authorised to view,
          play, print and download copyrighted documents, audio and video found
          on Our Website for personal, informational, and noncommercial purposes
          only.
        </span>
      </p>
      <p>
        <span style={{ fontWeight: 400 }}>
          Except as permitted by copyright law, You may not modify any of the
          materials and You may not copy, distribute, transmit, display,
          perform, reproduce, publish, license, create derivative works from,
          transfer or sell any information or work contained on the Website.
        </span>
      </p>
      <ul>
        <ol>
          <li style={{ fontWeight: 400 }}>
            <h2>
              <span style={{ fontWeight: 400 }}>Your Account</span>
            </h2>
          </li>
        </ol>
      </ul>
      <p>
        <span style={{ fontWeight: 400 }}>
          In consideration of Your use of the Website, You will:
        </span>
      </p>
      <ul>
        <li style={{ fontWeight: 400 }}>
          <span style={{ fontWeight: 400 }}>
            provide true, accurate, current and complete information about
            Yourself as prompted by the Website's registration page (such
            information being the &ldquo;
          </span>
          <strong>Account Data</strong>
          <span style={{ fontWeight: 400 }}>&rdquo;); and</span>
        </li>
        <li style={{ fontWeight: 400 }}>
          <span style={{ fontWeight: 400 }}>
            maintain and promptly update the Account Data to keep it true,
            accurate, current and complete.
          </span>
        </li>
      </ul>
      <p>
        <span style={{ fontWeight: 400 }}>
          You are entirely responsible for the security and confidentiality of
          Your password and Account. You will not share Your Account Data or
          Your username and password with any third party or permit any third
          party to login to the Website using Your Account Data. Furthermore,
          You are entirely responsible for any and all activities that occur
          under Your Account. You are responsible for taking precautions and
          providing security measures best suited for Your situation and
          intended use of the Services and Website.
        </span>
      </p>
      <p>
        <span style={{ fontWeight: 400 }}>
          You agree to immediately notify Us of any unauthorised use of Your
          Account or any other breach of security of which You become aware.
        </span>
      </p>
      <p>
        <span style={{ fontWeight: 400 }}>
          The Website may also include a tool that allows You to sign in or
          register using information from Your accounts in third party services,
          such as Facebook, Google, or Apple ID. These third-party services are
          not related to the Website, and Your use of such third-party services
          is subject to the terms and policies of those services.
        </span>
      </p>
      <p>
        <span style={{ fontWeight: 400 }}>
          For more information on how We collect, store, use, and share Your
          Account Data, please check Our{" "}
        </span>
        <a href="http://kantinapp.com.cy/privacypolicy">
          <span style={{ fontWeight: 400 }}>Privacy Policy</span>
        </a>
      </p>
      <ul>
        <ol>
          <li style={{ fontWeight: 400 }}>
            <h2>
              <span style={{ fontWeight: 400 }}>Payment and Refunds</span>
            </h2>
          </li>
        </ol>
      </ul>
      <p>&nbsp;</p>
      <p>
        <span style={{ fontWeight: 400 }}>
          Once an order has been placed it will be treated as confirmed.&nbsp;
        </span>
      </p>
      <p>&nbsp;</p>
      <p>
        <span style={{ fontWeight: 400 }}>
          Although unlikely, we may contact you after placing an order to
          confirm aspects of the order, including but not limited to the items
          ordered, the price, the delivery or pick up location or the estimated
          time a vendor may need to complete your order. For this reason, you
          are required to share your contact information with us, including your
          first and last name, mobile phone number, email address or any other
          information we may require.&nbsp;
        </span>
      </p>
      <p>&nbsp;</p>
      <p>
        <span style={{ fontWeight: 400 }}>
          It is your responsibility to provide accurate information and bring to
          our attention any incorrect details.&nbsp;
        </span>
      </p>
      <p>&nbsp;</p>
      <p>
        <span style={{ fontWeight: 400 }}>
          Further, we may contact you to inform you about the availability of
          products or to alter the price of an order and agree on a change.
          Anything agreed is conclusive and we will communicate this change to
          you. In case you are not available at the time we retain the right to
          cancel your order. In that case, the provisions of the cancellation
          and refund policy are outlined below.&nbsp;
        </span>
      </p>
      <p>
        <span style={{ fontWeight: 400 }}>
          In order to process Your order, You will be asked to provide billing
          information such as name, billing address and credit card information
          to our third party payment processor (&ldquo;
        </span>
        <strong>Payment Processor</strong>
        <span style={{ fontWeight: 400 }}>
          &rdquo;). The Payment Processor is responsible and We shall not be
          liable for any matter in connection with the processing of Your
          payments.&nbsp;
        </span>
      </p>
      <p>
        <span style={{ fontWeight: 400 }}>
          We act as the Vendor&rsquo;s payment agent for the limited purpose of
          accepting payments from you on behalf of the Vendor. Upon your payment
          of amounts to us, which are due to the Vendor, your payment obligation
          to the Vendor for such amounts is completed, and we are responsible
          for remitting such amounts, to the Vendor. You shall not, under any
          circumstances whatsoever, make any payment directly to the
          Vendor.&nbsp;
        </span>
      </p>
      <p>
        <span style={{ fontWeight: 400 }}>
          You agree to pay Us for any confirmed orders in accordance with these
          Terms and Conditions by the methods described on the Services. You
          hereby authorize the collection of such amounts by charging the credit
          card provided as part of requesting the booking, either directly by us
          or indirectly, via a third-party online payment processor or by one of
          the payment methods described on the Services.&nbsp;
        </span>
      </p>
      <p>
        <span style={{ fontWeight: 400 }}>
          You will be required to provide your credit or debit card details to
          the approved payment processor while making the payment. In this
          regard you agree to provide correct and accurate credit/ debit card
          details to the approved payment processor for availing the Services.
          You shall not use the credit/ debit card which is not lawfully owned
          by you, i.e. in any transaction, you must use your own credit/ debit
          card. The information provided by you will not be utilized or shared
          with any Third Party unless required in relation to fraud
          verifications or by law, regulation or court order. You will be solely
          responsible for the security and confidentiality of your credit/ debit
          card details. We expressly disclaim all liabilities that may arise as
          a consequence of any unauthorized use of your credit/ debit
          card.&nbsp;
        </span>
      </p>
      <p>
        <span style={{ fontWeight: 400 }}>
          If you are directed to our third party payment processor, you may be
          subject to terms and conditions governing use of that third
          party&rsquo;s service and that third party&rsquo;s personal
          information collection practices. Please review such terms and
          conditions and privacy policy before using the services. Once
          you&rsquo;re confirmed booking transaction is complete you will
          receive a confirmation email summarizing your confirmed booking.&nbsp;
        </span>
      </p>
      <p>
        <span style={{ fontWeight: 400 }}>
          Please note that we cannot control any amount that may be charged to
          you by your bank related to our collection of the total amount, and we
          disclaim all liability in this regard.&nbsp;
        </span>
      </p>
      <p>
        <span style={{ fontWeight: 400 }}>
          You agree to notify Us or the Payment Processor about any billing
          problems or discrepancies within 60 days after they first appear on
          Your Account statement. If You do not do so within 60 days, You agree
          that You waive Your right to dispute such problems or discrepancies.
        </span>
      </p>
      <p>
        <span style={{ fontWeight: 400 }}>
          We may partner with and use other third party payment service
          providers to handle all payments. We will notify You of such change by
          e-mail not less than 30 days before it takes effect.
        </span>
      </p>
      <p>
        <span style={{ fontWeight: 400 }}>
          In case that an item is not available we will inform you of the
          unavailability and you will be entitled to cancel the entire order and
          shall be entitled to a refund in accordance with our refund
          policy.&nbsp;
        </span>
      </p>
      <p>
        <span style={{ fontWeight: 400 }}>
          We reserve the sole right to cancel your order in the following
          circumstance:&nbsp;
        </span>
      </p>
      <ol>
        <li style={{ fontWeight: 400 }}>
          <span style={{ fontWeight: 400 }}>
            in the event of the designated address following outside the
            delivery zone offered by us or the location provided is not
            accurate; &nbsp;
          </span>
        </li>
        <li style={{ fontWeight: 400 }}>
          <span style={{ fontWeight: 400 }}>
            failure to contact you by phone or email at the time of confirming
            the order booking;&nbsp;
          </span>
        </li>
        <li style={{ fontWeight: 400 }}>
          <span style={{ fontWeight: 400 }}>
            failure&nbsp;to deliver your order due to lack of information,
            direction or authorization from you at the time of delivery;
            or&nbsp;
          </span>
        </li>
        <li style={{ fontWeight: 400 }}>
          <span style={{ fontWeight: 400 }}>
            unavailability of all the items ordered by you at the time of
            booking the order; or&nbsp;
          </span>
        </li>
        <li style={{ fontWeight: 400 }}>
          <span style={{ fontWeight: 400 }}>
            failure due to reasons beyond our control or attributable to the
            Vendor.&nbsp;
          </span>
        </li>
      </ol>
      <p>
        <span style={{ fontWeight: 400 }}>
          You shall be entitled to a refund only if you pre-pay for your order
          at the time of placing your order on the Services and only in the
          event of any of the following circumstances:&nbsp;
        </span>
      </p>
      <ol>
        <li style={{ fontWeight: 400 }}>
          <span style={{ fontWeight: 400 }}>
            your order packaging has been tampered or damaged at the time of
            delivery or collection;&nbsp;
          </span>
        </li>
        <li style={{ fontWeight: 400 }}>
          <span style={{ fontWeight: 400 }}>
            us cancelling your order;&nbsp;
          </span>
        </li>
        <li style={{ fontWeight: 400 }}>
          <span style={{ fontWeight: 400 }}>
            you cancelling the order at the time of confirmation due to
            unavailability of the items you ordered for at the time of
            booking.&nbsp;
          </span>
        </li>
      </ol>
      <p>
        <span style={{ fontWeight: 400 }}>
          Our decision on refunds shall be at our sole discretion and shall be
          final and binding.&nbsp;
        </span>
      </p>
      <p>
        <span style={{ fontWeight: 400 }}>
          All refund amounts shall be credited to your account within 3-4
          business days in&nbsp;accordancewith&nbsp;the terms that may be
          stipulated by the bank which has issued the credit / debit card.&nbsp;
        </span>
      </p>
      <p>
        <span style={{ fontWeight: 400 }}>
          You agree and acknowledge that we shall not be responsible for:&nbsp;
        </span>
      </p>
      <ol>
        <li style={{ fontWeight: 400 }}>
          <span style={{ fontWeight: 400 }}>
            The services or goods provided by the Vendor including, but not
            limited, serving of food orders suiting your requirements and
            needs;&nbsp;
          </span>
        </li>
        <li style={{ fontWeight: 400 }}>
          <span style={{ fontWeight: 400 }}>
            The Vendor&rsquo;s services or goods not being up to your
            expectations or leading to any loss, harm or damage to you;&nbsp;
          </span>
        </li>
        <li style={{ fontWeight: 400 }}>
          <span style={{ fontWeight: 400 }}>
            The availability or unavailability of certain items on the menu;
            or&nbsp;
          </span>
        </li>
        <li style={{ fontWeight: 400 }}>
          <span style={{ fontWeight: 400 }}>
            The Vendor serving the incorrect orders.&nbsp;
          </span>
        </li>
      </ol>
      <p>
        <span style={{ fontWeight: 400 }}>
          The details of the menu and price list available on the Services are
          based on the information provided by the Vendors and we shall not be
          responsible for any change or cancellation or unavailability.&nbsp;
        </span>
      </p>
      <p>
        <span style={{ fontWeight: 400 }}>
          You may not be able to avail our Services if your delivery location is
          outside our current scope of Service. We will keep you informed of the
          same at the time of confirming your order booking.&nbsp;
        </span>
      </p>
      <p>
        <span style={{ fontWeight: 400 }}>
          You understand that delivery periods quoted to you at the time of
          confirming the order is an approximate estimate and may vary. We will
          not be responsible for any delay in the delivery of your order.&nbsp;
        </span>
      </p>
      <p>
        <span style={{ fontWeight: 400 }}>
          You order will be only delivered to the address designated by you at
          the time of placing the order on the Services. We reserve the right to
          cancel the order, in our sole discretion, in the event of any change
          to the place of delivery and you shall not be entitled to any refund
          for the same. Delivery in the event of change of the delivery location
          shall be at our sole discretion.&nbsp;
        </span>
      </p>
      <p>
        <span style={{ fontWeight: 400 }}>
          You shall undertake to provide adequate directions, information
          and&nbsp;authorisations&nbsp;to accept delivery. In the event of any
          failure to accept delivery, failure to deliver within the estimated
          time due to your failure to provide appropriate instructions, or
          authorizations, then such goods shall be deemed to have been delivered
          to you and all risk and responsibility in relation to such goods shall
          pass to you and you shall not be entitled to any refund for the same.
          Our decision in relation to this shall be final and binding.&nbsp;
        </span>
      </p>
      <p>
        <span style={{ fontWeight: 400 }}>
          You understand that our liability ends once your order has been
          delivered to you.&nbsp;
        </span>
      </p>
      <p>
        <span style={{ fontWeight: 400 }}>
          You confirm that we shall not be responsible for any deficiency in
          payment of consideration payable towards the goods purchased from the
          Services.&nbsp;
        </span>
      </p>
      <p>
        <span style={{ fontWeight: 400 }}>
          Each purchase on the Service shall contain necessary instructions to
          redeem the Services. The terms of the services shall be governed by
          these Terms of Use and any other terms as set out in such document
          confirm such sale of service. You shall not be entitled to receive any
          credit, refund or cash back for the value of the goods sold if you
          fail to redeem the goods within the expiry date or in accordance with
          the terms therein.&nbsp;
        </span>
      </p>
      <p>
        <span style={{ fontWeight: 400 }}>
          You agree and acknowledge that neither us nor the Vendor shall be
          liable in the event of you failing to adhere to the Terms of
          Use.&nbsp;
        </span>
      </p>
      <p>
        <span style={{ fontWeight: 400 }}>
          We do not offer any refunds against goods already purchased from the
          Services unless an error that is directly attributable to us has
          occurred during the purchase of such product or services.
        </span>
      </p>
      <ul>
        <ol>
          <li style={{ fontWeight: 400 }}>
            <h2>
              <span style={{ fontWeight: 400 }}>Website Information</span>
            </h2>
          </li>
        </ol>
      </ul>
      <p>
        <span style={{ fontWeight: 400 }}>
          We attempt to ensure that information on the Website is complete,
          accurate and current. Despite Our efforts, the information on the
          Website may occasionally be inaccurate, incomplete or out of date. We
          make no representation as to the completeness, accuracy or currency of
          any information on the Website.
        </span>
      </p>
      <p>
        <span style={{ fontWeight: 400 }}>
          Through Your use of the Website, You may be exposed to content that
          You may find offensive, objectionable, harmful, inaccurate or
          deceptive. By using Our Website, You assume all associated
          risks.&nbsp;
        </span>
      </p>
      <ul>
        <ol>
          <li style={{ fontWeight: 400 }}>
            <h2>
              <span style={{ fontWeight: 400 }}>Security</span>
            </h2>
          </li>
        </ol>
      </ul>
      <p>
        <span style={{ fontWeight: 400 }}>
          Information provided by you through http://kantinapp.com.cy is stored
          on a secured private server. While we have taken reasonable
          precautions to protect your personal information, no security system
          is impenetrable and we cannot guarantee the security of information
          that you provide to us via the Internet, through our Website, through
          online ordering, or in databases stored on our servers.&nbsp;
        </span>
      </p>
      <p>
        <span style={{ fontWeight: 400 }}>
          Our online ordering functionality employs Secure Sockets Layer (SSL)
          software, the industry standard and among the best software available
          today for secure commerce transactions. It encrypts all of your
          personal information. Please see our{" "}
        </span>
        <a href="http://kantinapp.com.cy/privacypolicy">
          <span style={{ fontWeight: 400 }}>Privacy Policy</span>
        </a>
        <span style={{ fontWeight: 400 }}> for full details on security.</span>
      </p>
      <ul>
        <ol>
          <li style={{ fontWeight: 400 }}>
            <h2>
              <span style={{ fontWeight: 400 }}>Links to this Website</span>
            </h2>
          </li>
        </ol>
      </ul>
      <p>
        <span style={{ fontWeight: 400 }}>
          Running or displaying the Website or any information or material
          displayed on the Website in frames or through similar means on another
          site without Our prior written permission is prohibited.
        </span>
      </p>
      <p>
        <span style={{ fontWeight: 400 }}>
          We grant You a nonexclusive right to display at Your site any link
          that would permit any user to go from Your site to Our Website. You
          obtain no rights other than the right to link to Our Website. Further,
          We do not guarantee, approve, or endorse the information or products
          available on Your site.
        </span>
      </p>
      <p>
        <span style={{ fontWeight: 400 }}>
          You agree to not use and display the links in such a manner that is
          misleading, defamatory, infringing, libellous, disparaging, obscene,
          or otherwise objectionable to Us. You agree to not use the links in
          any manner that is likely to reduce, diminish or damage the goodwill,
          value or reputation associated with Us. You warrant that Your site
          does not include any material, and does not contain links to sites
          displaying any material, which is harmful, pornographic, abusive,
          hateful, obscene threatening, defamatory, or which encourages illegal
          activity. Upon notice from Us, You shall promptly remove the links.
        </span>
      </p>
      <p>
        <span style={{ fontWeight: 400 }}>
          If Your wish to obtain written consent from Us, please contact us
          using our{" "}
        </span>
        <a href="http://kantinapp.com.cy">
          <span style={{ fontWeight: 400 }}>contact page</span>
        </a>
      </p>
      <ul>
        <ol>
          <li style={{ fontWeight: 400 }}>
            <h2>
              <span style={{ fontWeight: 400 }}>Third Party Links</span>
            </h2>
          </li>
        </ol>
      </ul>
      <p>
        <span style={{ fontWeight: 400 }}>
          From time to time, the Website may contain links to sites that We and
          Our affiliates do not own, operate nor control. All such links are
          provided solely for Your convenience. If You use these links, You will
          leave the Website. Neither We nor any of Our respective affiliates are
          responsible for any content, material or other information located on
          or accessible from any other site. Neither We nor any of Our
          respective affiliates endorse, guarantee, or make any representations
          or warranties regarding any other site, or any content, materials or
          other information located or accessible from any other site, or the
          results that You may obtain from using any other site.
        </span>
      </p>
      <p>
        <span style={{ fontWeight: 400 }}>
          We do not control, endorse, sponsor or approve of the third parties or
          their content nor do We take any responsibility for any aspect of
          these websites or their content. If You decide to access any other
          site linked to or from this Website, You do so entirely at Your own
          risk. You agree to be aware when You leave the Website and to read the
          privacy statements of these sites. You will evaluate the security and
          trustworthiness of any site connected to or accessed through the
          Website before disclosing any personal information to them. We do not
          accept any responsibility for any loss or damage in whatever manner,
          howsoever caused, resulting from Your disclosure of personal
          information to those third-party sites.
        </span>
      </p>
      <ul>
        <ol>
          <li style={{ fontWeight: 400 }}>
            <h2>
              <span style={{ fontWeight: 400 }}>
                Intellectual Property Rights
              </span>
            </h2>
          </li>
        </ol>
      </ul>
      <p>
        <span style={{ fontWeight: 400 }}>
          We claim copyright and all other intellectual property rights to all
          the material on the Website, including, but not limited to the words,
          information, graphics, designs, logos, trademarks, photographs, icons,
          drawings and text.
        </span>
      </p>
      <p>
        <span style={{ fontWeight: 400 }}>
          Our intellectual property is protected under copyright, trademark and
          other intellectual property laws.
        </span>
      </p>
      <p>
        <span style={{ fontWeight: 400 }}>
          As part of this Agreement, You agree to not reproduce, distribute,
          sell, publish or broadcast any of the material found on this Website
          without Our prior written consent.
        </span>
      </p>
      <ul>
        <ol>
          <li style={{ fontWeight: 400 }}>
            <h2>
              <span style={{ fontWeight: 400 }}>Copyright Notice</span>
            </h2>
          </li>
        </ol>
      </ul>
      <p>
        <span style={{ fontWeight: 400 }}>
          All Website design, graphics, text selections, arrangements, and all
          software are of Copyright &copy; 2020, Planet Caravan Software Ltd or
          its licensors. ALL RIGHTS RESERVED.
        </span>
      </p>
      <ul>
        <ol>
          <li style={{ fontWeight: 400 }}>
            <h2>
              <span style={{ fontWeight: 400 }}>Disclaimers</span>
            </h2>
          </li>
        </ol>
      </ul>
      <p>
        <strong>
          YOUR USE OF THE WEBSITE IS AT YOUR OWN RISK. THE INFORMATION,
          MATERIALS AND SERVICES PROVIDED ON OR THROUGH THE WEBSITE ARE PROVIDED
          AS IS WITHOUT ANY WARRANTIES OF ANY KIND INCLUDING WARRANTIES OF
          MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, SECURITY OR
          NON-INFRINGEMENT OF INTELLECTUAL PROPERTY. NEITHER WE NOR ANY OF OUR
          AFFILIATES WARRANT THE ACCURACY OR COMPLETENESS OF THE INFORMATION,
          MATERIALS OR SERVICES PROVIDED ON OR THROUGH THE WEBSITE. THE
          INFORMATION, MATERIALS AND SERVICES PROVIDED ON OR THROUGH THE WEBSITE
          MAY BE OUT OF DATE, AND NEITHER US, NOR ANY OF OUR AFFILIATES MAKE ANY
          COMMITMENT OR ASSUME ANY DUTY TO UPDATE SUCH INFORMATION, MATERIALS OR
          SERVICES. THE FOREGOING EXCLUSIONS OF IMPLIED WARRANTIES DO NOT APPLY
          TO THE EXTENT PROHIBITED BY LAW.&nbsp;
        </strong>
      </p>
      <p>
        <strong>
          NO ADVICE OR INFORMATION, WHETHER ORAL OR WRITTEN, OBTAINED FROM THE
          WEBSITE OR THROUGH OUR SERVICES WILL CREATE ANY WARRANTY NOT EXPRESSLY
          MADE HEREIN.
        </strong>
      </p>
      <ul>
        <ol>
          <li style={{ fontWeight: 400 }}>
            <h2>
              <span style={{ fontWeight: 400 }}>Indemnity</span>
            </h2>
          </li>
        </ol>
      </ul>
      <p>
        <span style={{ fontWeight: 400 }}>
          You agree to defend, indemnify and hold Us and any of Our affiliated
          company or individual harmless from any and all liabilities, costs,
          and expenses, including reasonable attorneys&rsquo; fees, related to
          or in connection with (a) the use of the Website or the internet or
          Your placement or transmission of any message or information on this
          Website by You or Your authorised users; (b) Your violation of any
          term of this Agreement, including without limitation, Your breach of
          any of the Representations and Warranties above; (c) Your violation of
          any third party right, including without limitation any right of
          privacy, publicity rights or Intellectual Property Rights; (d) Your
          violation of any law, rule or regulation of Cyprus or any other
          country; (e) any claim or damages that arise as a result of any User
          Content that You provide to Us; or (f) any other party&rsquo;s access
          and use of the Website with Your unique username, password or other
          appropriate security code.
        </span>
      </p>
      <ul>
        <ol>
          <li style={{ fontWeight: 400 }}>
            <h2>
              <span style={{ fontWeight: 400 }}>Communication</span>
            </h2>
          </li>
        </ol>
      </ul>
      <p>
        <span style={{ fontWeight: 400 }}>
          We reserve the right to send You an electronic mail for the purpose of
          informing You of changes or additions to the Website or this
          Agreement.
        </span>
      </p>
      <p>
        <span style={{ fontWeight: 400 }}>Except as disclosed in Our </span>
        <a href="http://kantinapp.com.cy/privacypolicy">
          <span style={{ fontWeight: 400 }}>Privacy Policy</span>
        </a>
        <span style={{ fontWeight: 400 }}>
          , We will not monitor, edit, or disclose the contents of Your e-mail,
          unless required in the course of normal maintenance of the Website and
          its systems, or unless required to do so by law or in the good-faith
          belief that such action is necessary to: (a) comply with the law or
          comply with legal process served on Us or the Website; (b) protect and
          defend Our rights or property of, the Website, or the users of the
          Website; or (c) act in an emergency to protect the personal safety of
          Our guests, the Website, or the public.
        </span>
      </p>
      <ul>
        <ol>
          <li style={{ fontWeight: 400 }}>
            <h2>
              <span style={{ fontWeight: 400 }}>Revisions</span>
            </h2>
          </li>
        </ol>
      </ul>
      <p>
        <span style={{ fontWeight: 400 }}>
          This Agreement is subject to Our revision and changes from time to
          time. Your continued use of the Website after the revision and changes
          will mean that You agree, without qualification, to the revised
          Agreement.
        </span>
      </p>
      <p>
        <span style={{ fontWeight: 400 }}>
          If You are not a registered Subscriber of the Website, then You can
          review revisions to this Agreement by regularly checking this page.
        </span>
      </p>
      <p>
        <span style={{ fontWeight: 400 }}>
          Material revisions to this Agreement will be indicated by an updated
          effective date at the top left corner of this page and by a notice
          posted on the homepage of the Website.
        </span>
      </p>
      <p>
        <span style={{ fontWeight: 400 }}>
          It is Your responsibility to remain apprised of any revisions to this
          Agreement and to remain in compliance therewith.
        </span>
      </p>
      <p>
        <span style={{ fontWeight: 400 }}>
          Should You object to any such revisions to this Agreement or become
          dissatisfied with the Website in any way, Your only recourse is to
          immediately terminate Your membership or discontinue use of the
          Website. Otherwise, continuing to use, visit, access or use the
          Website after the effective date means that You agree to be bound by
          any and all revisions.
        </span>
      </p>
      <ul>
        <ol>
          <li style={{ fontWeight: 400 }}>
            <h2>
              <span style={{ fontWeight: 400 }}>Force Majeure</span>
            </h2>
          </li>
        </ol>
      </ul>
      <p>
        <span style={{ fontWeight: 400 }}>
          We shall not be responsible for damages or for delays or failures in
          performance resulting from acts or occurrences beyond Our reasonable
          control, including, without limitation: fire, lightning, explosion,
          power surge or failure, water, acts of God, war, revolution, civil
          commotion or acts of civil or military authorities or public enemies;
          epidemics, pandemics; any law, order, regulation, ordinance, or
          requirement of any government or legal body or any representative of
          any such government or legal body; or labor unrest, including without
          limitation, strikes, slowdowns, picketing, or boycotts; inability to
          secure raw materials, transportation facilities, fuel or energy
          shortages, or acts or omissions of other common carriers.
        </span>
      </p>
      <ul>
        <ol>
          <li style={{ fontWeight: 400 }}>
            <h2>
              <span style={{ fontWeight: 400 }}>User Feedback</span>
            </h2>
          </li>
        </ol>
      </ul>
      <p>
        <span style={{ fontWeight: 400 }}>
          We welcome and encourage You to provide feedback, comments, and
          suggestions for improvements of the Website (&ldquo;
        </span>
        <strong>Feedback</strong>
        <span style={{ fontWeight: 400 }}>
          &rdquo;). You may submit Feedback by emailing Us at
          michalis@kantinapp.info. You acknowledge and agree that if You submit
          any Feedback to Us, You hereby grant to Us a non-exclusive, worldwide,
          perpetual, irrevocable, fully-paid, royalty-free, sub-licensable
          (through several tiers) and transferable license under any and all
          intellectual property rights that You own or control in relation to
          the Feedback to use, reproduce, view, communicate to the public by any
          means, print, copy (whether onto hard disk or other media), edit,
          translate, perform and display (publicly or otherwise), distribute,
          redistribute, modify, adapt, make, sell, offer to sell, transmit,
          license, transfer, stream, broadcast, create derivative works from,
          and otherwise use and exploit the Feedback for any purpose.
        </span>
      </p>
      <ul>
        <ol>
          <li style={{ fontWeight: 400 }}>
            <h2>
              <span style={{ fontWeight: 400 }}>Contact Us</span>
            </h2>
          </li>
        </ol>
      </ul>
      <p>
        <span style={{ fontWeight: 400 }}>
          If You do not understand any of the foregoing terms and conditions or
          if You have any questions or comments, please contact Us through our
          contact form or by sending an email to: michalis@kantinapp.info
        </span>
      </p>
      <p>
        <strong>
          YOU ACKNOWLEDGE THAT YOU HAVE READ THESE TERMS, UNDERSTAND THE TERMS,
          AND WILL BE BOUND BY THESE TERMS. YOU FURTHER ACKNOWLEDGE THAT THESE
          TERMS TOGETHER WITH THE PRIVACY POLICY REPRESENT THE COMPLETE AND
          EXCLUSIVE STATEMENT OF THE AGREEMENT BETWEEN US AND THAT IT SUPERSEDES
          ANY PROPOSAL OR PRIOR AGREEMENT ORAL OR WRITTEN, AND ANY OTHER
          COMMUNICATIONS BETWEEN US RELATING TO THE SUBJECT MATTER OF THIS
          AGREEMENT.
        </strong>
      </p>
      <p>
        <span style={{ fontWeight: 400 }}>Privacy Policy</span>
      </p>
      <p>&nbsp;</p>
      <p>
        <span style={{ fontWeight: 400 }}>Dear Customer,&nbsp;</span>
      </p>
      <p>&nbsp;</p>
      <p>
        <span style={{ fontWeight: 400 }}>
          thanks for taking an interest in Kantinapp and our privacy policy. We
          take matters of privacy seriously and took time to devise our privacy
          policy and communicate it in simple language for everyone to
          understand. After all, there is no purpose in writing a policy
          statement that only lawyers can comprehend!&nbsp;
        </span>
      </p>
      <p>&nbsp;</p>
      <p>
        <span style={{ fontWeight: 400 }}>
          Therefore, the purpose of this Privacy Policy is to inform you on how
          our company, Planet Caravan Software Ltd, collects, uses, discloses
          and processes personal data acquired through the use of{" "}
        </span>
        <a href="http://www.kantinapp.com.cy">
          <span style={{ fontWeight: 400 }}>http://www.kantinapp.com.cy</span>
        </a>
        <span style={{ fontWeight: 400 }}> and the </span>
        <em>
          <span style={{ fontWeight: 400 }}>Kantinapp </span>
        </em>
        <span style={{ fontWeight: 400 }}>mobile application.&nbsp;</span>
      </p>
      <p>&nbsp;</p>
      <p>
        <span style={{ fontWeight: 400 }}>
          Please accept this Privacy Policy as our way of notifying all our
          visitors about their rights in accordance with local and EU
          legislation. All you need to know about how we collect, store and
          process information can be found in this privacy policy statement. In
          case you want to contact us to find out more about our privacy policy,
          or to ask a question, do not hesitate for a minute to send us an email
          at:{" "}
        </span>
        <a href="mailto:michalis@kantinapp.com.cy">
          <span style={{ fontWeight: 400 }}>michalis@kantinapp.com.cy</span>
        </a>
      </p>
      <p>&nbsp;</p>
      <p>
        <strong>&lsquo;You&rsquo;</strong>
        <span style={{ fontWeight: 400 }}>
          , in this instance, refers to the user of Kantinapp Mobile app and
          Kantinapp.com.cy irrespective of whether you make use of the services
          available such as placing an order or completing a transaction. In
          simple terms, &lsquo;you&rsquo; refers to any person who engages with
          Kantinapp Mobile App and related websites such as{" "}
        </span>
        <a href="http://www.kantinapp.com.cy">
          <span style={{ fontWeight: 400 }}>www.kantinapp.com.cy</span>
        </a>
        <span style={{ fontWeight: 400 }}>.&nbsp;</span>
      </p>
      <p>&nbsp;</p>
      <p>
        <span style={{ fontWeight: 400 }}>
          By accessing and using Kantinapp Mobile App and related websites you
          agree to the terms and conditions of the Privacy Policy. We outline
          below the way we use and disclose any Personal Information and, by
          accessing and using our services, you expressly consent to the manner
          we treat this information. If you do not agree with the terms and
          conditions of this Privacy Policy, please do not proceed further or
          use or access this Website.
        </span>
      </p>
      <p>&nbsp;</p>
      <p>
        <strong>&lsquo;We&rsquo;</strong>
        <span style={{ fontWeight: 400 }}>
          , in this instance, refers to the company that owns{" "}
        </span>
        <em>
          <span style={{ fontWeight: 400 }}>Kantinapp. </span>
        </em>
        <span style={{ fontWeight: 400 }}>
          The Mobile App and related website is owned and operated by Planet
          Caravan Software Ltd, a limited liability company registered in the
          Republic of Cyprus with registration number H413204&nbsp; and
          registered address at 8 Michalaki Karaoli Street, Anemomylos Building,
          4
        </span>
        <span style={{ fontWeight: 400 }}>th </span>
        <span style={{ fontWeight: 400 }}>
          floor, 1095 Nicosia, Cyprus. Therefore, In this Privacy Policy, "We",
          "Us", and "Our" refer to Planet Caravan Software Ltd.&nbsp;
        </span>
      </p>
      <p>
        <em>
          <span style={{ fontWeight: 400 }}>Kantinapp </span>
        </em>
        <span style={{ fontWeight: 400 }}>
          is an mobile ordering platform through which you, the users of the app
          and website, have the ability to:
        </span>
      </p>
      <ol>
        <li style={{ fontWeight: 400 }}>
          <span style={{ fontWeight: 400 }}>
            Order food and/or drinks and/or other items from the Vendors and
            locations available;&nbsp;
          </span>
        </li>
        <li style={{ fontWeight: 400 }}>
          <span style={{ fontWeight: 400 }}>
            Pre-pay for food, drinks or other items ordered through the Vendors
            by the banking channels available.&nbsp;
          </span>
        </li>
      </ol>
      <p>
        <span style={{ fontWeight: 400 }}>
          Should You have anu questions about this Privacy Policy or wish to
          exercise your legal rights you can contact us at:
        </span>
      </p>
      <p>&nbsp;</p>
      <p>
        <span style={{ fontWeight: 400 }}>Email: </span>
        <a href="mailto:michalis@kantinapp.com.cy">
          <span style={{ fontWeight: 400 }}>michalis@kantinapp.com.cy</span>
        </a>
      </p>
      <p>
        <span style={{ fontWeight: 400 }}>
          Address: 8 Michalaki Karaoli Street, Anemomylos Building, 4
        </span>
        <span style={{ fontWeight: 400 }}>th </span>
        <span style={{ fontWeight: 400 }}>floor, 1095 Nicosia, Cyprus.</span>
      </p>
      <p>
        <span style={{ fontWeight: 400 }}>
          We are committed to protecting Your privacy. This Privacy Policy
          describes how We collect, use, store, share and protect the
          information collected through http://kantinapp.com.cy (the &ldquo;
        </span>
        <strong>Website</strong>
        <span style={{ fontWeight: 400 }}>&rdquo;).</span>
      </p>
      <p>
        <span style={{ fontWeight: 400 }}>
          This Privacy Policy is incorporated into and subject to Our{" "}
        </span>
        <a href="http://kantinapp.com.cy/terms-and-conditions">
          <span style={{ fontWeight: 400 }}>Terms and Conditions</span>
        </a>
        <span style={{ fontWeight: 400 }}>.</span>
        <span style={{ fontWeight: 400 }}>
          {" "}
          Capitalised words used but not defined in this Privacy Policy have the
          meaning given to them in the Terms and Conditions.
        </span>
      </p>
      <p>
        <span style={{ fontWeight: 400 }}>
          Please be advised that the practices described in this Privacy Policy
          apply only to information gathered online through the Website. This
          Privacy Policy applies where We are acting as a data controller with
          respect to the Personal Data of visitors, users, clients and customers
          (&ldquo;You,&rdquo; or &ldquo;Your&rdquo;); in other words, where We
          determine the purposes and means of the processing of that Personal
          Data.
        </span>
      </p>
      <p>
        <span style={{ fontWeight: 400 }}>
          PLEASE READ THIS PRIVACY POLICY CAREFULLY because it affects Your
          rights under the law. You confirm that You have read and agreed to be
          bound by this Privacy Policy. If You do not agree with this Privacy
          Policy, You cannot use, access, create or publish in the Website. This
          Privacy Policy may change as We continuously improve the Website, so
          please check it periodically.
        </span>
      </p>
      <p>
        <span style={{ fontWeight: 400 }}>
          We reserve the right to modify or amend the Privacy Policy from time
          to time without notice. Your continued use of the Website following
          the posting of changes to these terms will mean You accept those
          changes. If We intend to apply the modifications or amendments to this
          Privacy Policy retroactively or to Personal Data already in Our
          possession, We will provide You with notice of the modifications or
          amendments.&nbsp;
        </span>
      </p>
      <ol>
        <li style={{ fontWeight: 400 }}>
          <h2>
            <span style={{ fontWeight: 400 }}>Your Data</span>
          </h2>
        </li>
      </ol>
      <p>
        <span style={{ fontWeight: 400 }}>
          We strive to provide you with the best possible service. In order to
          provide this service we may need to collect information from you from
          time to time. At all times we try to only collect the information we
          need for the particular function or activity we are carrying out and
          use it in accordance with this privacy policy.
        </span>
      </p>
      <p>
        <span style={{ fontWeight: 400 }}>
          We collect two basic types of information from You from Your use of
          the Website: Personal Data and Non-Personal Data.
        </span>
      </p>
      <ol>
        <li style={{ fontWeight: 400 }}>
          <h2>
            <span style={{ fontWeight: 400 }}>Personal Data</span>
          </h2>
        </li>
      </ol>
      <p>
        <span style={{ fontWeight: 400 }}>"</span>
        <strong>Personal Data</strong>
        <span style={{ fontWeight: 400 }}>
          " means any information relating to an identified or identifiable
          natural person; an identifiable natural person is one who can be
          identified, directly or indirectly, in particular by reference to an
          identifier such as a name, an identification number, location data, an
          online identifier or to one or more factors specific to the physical,
          economic, cultural or social identity of that natural person.
        </span>
      </p>
      <p>
        <span style={{ fontWeight: 400 }}>
          As a general matter, You can browse the Website without submitting
          Your Personal Data to Us. However, there are a number of circumstances
          in which You may supply Us with Your Personal Data.
        </span>
      </p>
      <h3>
        <span style={{ fontWeight: 400 }}>2.1 Data We Collect</span>
      </h3>
      <p>
        <span style={{ fontWeight: 400 }}>
          We collect the following Personal Data from You:
        </span>
      </p>
      <ul>
        <li style={{ fontWeight: 400 }}>
          <span style={{ fontWeight: 400 }}>Full Name;</span>
        </li>
        <li style={{ fontWeight: 400 }}>
          <span style={{ fontWeight: 400 }}>E-mail;</span>
        </li>
        <li style={{ fontWeight: 400 }}>
          <span style={{ fontWeight: 400 }}>Phone Number;</span>
        </li>
        <li style={{ fontWeight: 400 }}>
          <span style={{ fontWeight: 400 }}>Location.&nbsp;</span>
        </li>
      </ul>
      <h3>
        <span style={{ fontWeight: 400 }}>2.2 How We Collect Data</span>
      </h3>
      <p>
        <strong>
          The following are the most common ways in which You give Your Personal
          Data:
        </strong>
      </p>
      <ul>
        <li style={{ fontWeight: 400 }}>
          <span style={{ fontWeight: 400 }}>
            Submission of User Content on any part of the Website that permit
            it;
          </span>
        </li>
      </ul>
      <ol>
        <li style={{ fontWeight: 400 }}>
          <h2>
            <span style={{ fontWeight: 400 }}>
              Personal Data from third-party platforms
            </span>
          </h2>
        </li>
      </ol>
      <p>
        <span style={{ fontWeight: 400 }}>
          When You interact with the Website or Your Account through a
          third-party platform, such as Facebook, Google or Apple&nbsp; We may
          collect the Personal Data that You make available to Us on that page
          including Your account ID or handle. We will comply with the privacy
          policies of the corresponding platform and We will only collect and
          store such Personal Data that We are permitted to collect by these
          social media platforms.
        </span>
      </p>
      <p>
        <span style={{ fontWeight: 400 }}>
          If You choose to link or sign in to Your Account with or through a
          social networking service, We and that service may share certain
          information about You and Your activities. With Your consent, We also
          may share information about You and Your activities, including what
          Your name, town, what You view on the Website, with that social
          network's users.
        </span>
      </p>
      <p>
        <span style={{ fontWeight: 400 }}>
          Please do NOT supply any other person's Personal Data to Us, unless We
          prompt You to do so.
        </span>
      </p>
      <ol>
        <li style={{ fontWeight: 400 }}>
          <h2>
            <span style={{ fontWeight: 400 }}>Use of Personal Data</span>
          </h2>
        </li>
      </ol>
      <p>
        <span style={{ fontWeight: 400 }}>
          We do NOT sell or license Your Personal Data to any third party.
        </span>
      </p>
      <p>
        <span style={{ fontWeight: 400 }}>
          We may process any of Your Personal Data identified in this Privacy
          Policy where necessary for the establishment, exercise or defence of
          legal claims, whether in court proceedings or in an administrative or
          out-of-court procedure. The legal basis for this processing is Our
          legitimate interests, namely the protection and assertion of Our legal
          rights, Your legal rights and the legal rights of others.
        </span>
      </p>
      <p>
        <span style={{ fontWeight: 400 }}>
          In addition to the specific purposes for which We may process Your
          Personal Data set out in this clause, We may also process any of Your
          Personal Data where such processing is necessary for compliance with a
          legal obligation to which We are subject, or in order to protect Your
          vital interests or the vital interests of another natural person.
        </span>
      </p>
      <p>
        <span style={{ fontWeight: 400 }}>
          Specifically, We use Your Personal Data for the following:
        </span>
      </p>
      <ul>
        <li style={{ fontWeight: 400 }}>
          <span style={{ fontWeight: 400 }}>
            responding to Your queries and requests;
          </span>
        </li>
        <li style={{ fontWeight: 400 }}>
          <span style={{ fontWeight: 400 }}>investigating complaints;</span>
        </li>
        <li style={{ fontWeight: 400 }}>
          <span style={{ fontWeight: 400 }}>
            enforcing Our Terms and Conditions;
          </span>
        </li>
        <li style={{ fontWeight: 400 }}>
          <span style={{ fontWeight: 400 }}>
            as otherwise required or authorized by law or government agency.
          </span>
        </li>
        <li style={{ fontWeight: 400 }}>
          <span style={{ fontWeight: 400 }}>
            providing Goods and Services to You;
          </span>
        </li>
        <li style={{ fontWeight: 400 }}>
          <span style={{ fontWeight: 400 }}>verifying Your identity;</span>
        </li>
        <li style={{ fontWeight: 400 }}>
          <span style={{ fontWeight: 400 }}>
            rewards, competitions, marketing or other promotional materials;
          </span>
        </li>
        <li style={{ fontWeight: 400 }}>
          <span style={{ fontWeight: 400 }}>tracking sales data;</span>
        </li>
        <li style={{ fontWeight: 400 }}>
          <span style={{ fontWeight: 400 }}>
            providing tips, news and/or updates;
          </span>
        </li>
        <li style={{ fontWeight: 400 }}>
          <span style={{ fontWeight: 400 }}>creating personalized offers;</span>
        </li>
      </ul>
      <ol>
        <li style={{ fontWeight: 400 }}>
          <h2>
            <span style={{ fontWeight: 400 }}>Sharing Of Personal Data</span>
          </h2>
        </li>
      </ol>
      <p>
        <span style={{ fontWeight: 400 }}>
          We may share your Personal Data with the following:
        </span>
      </p>
      <p>
        <strong>Third-Party Suppliers</strong>
        <span style={{ fontWeight: 400 }}>. We may disclose Your </span>
        <strong>Enquiry Data</strong>
        <span style={{ fontWeight: 400 }}>
          {" "}
          to one or more of those selected third-party suppliers of Goods and
          Services identified on Our Website for the purpose of enabling them to
          contact You so that they can offer, market and sell relevant Goods
          and/or Services to You. Each such third party will act as a data
          controller in relation to the Enquiry Data that We supply to it; and
          upon contacting You, each such third party will supply to You a copy
          of its own privacy policy, which will govern that third party's use of
          Your Personal Data. The suppliers and providers with whom We may share
          Your information include:
        </span>
      </p>
      <ul>
        <li style={{ fontWeight: 400 }}>
          <span style={{ fontWeight: 400 }}>Social networking sites</span>
        </li>
        <li style={{ fontWeight: 400 }}>
          <span style={{ fontWeight: 400 }}>Analytics services</span>
        </li>
        <li style={{ fontWeight: 400 }}>
          <span style={{ fontWeight: 400 }}>
            IT service providers and web hosting companies
          </span>
        </li>
        <li style={{ fontWeight: 400 }}>
          <span style={{ fontWeight: 400 }}>
            Legal, auditing and other professional advisors or consultants
          </span>
        </li>
        <li style={{ fontWeight: 400 }}>
          <span style={{ fontWeight: 400 }}>
            Billing or data storage services
          </span>
        </li>
      </ul>
      <p>
        <strong>Other Purposes</strong>
        <span style={{ fontWeight: 400 }}>
          . In addition to the specific disclosures of Personal Data set out in
          this Section, We may disclose Your{" "}
        </span>
        <strong>Personal Data</strong>
        <span style={{ fontWeight: 400 }}>
          {" "}
          where such disclosure is necessary for compliance with a legal
          obligation to which We are subject, or in order to protect Our vital
          interests, Your vital interests, or the vital interests of another
          natural person. We may also disclose Your Personal Data where such
          disclosure is necessary for the establishment, exercise or defence of
          legal claims, whether in court proceedings or in an administrative or
          out-of-court procedure.
        </span>
      </p>
      <ol>
        <li style={{ fontWeight: 400 }}>
          <h2>
            <span style={{ fontWeight: 400 }}>
              Retaining and Deleting Personal Data
            </span>
          </h2>
        </li>
      </ol>
      <p>
        <span style={{ fontWeight: 400 }}>
          Personal Data that We process for any purpose shall not be kept for
          longer than is necessary for that purpose or those purposes.
        </span>
      </p>
      <p>
        <span style={{ fontWeight: 400 }}>
          We will retain Your Personal Data for a maximum period of{" "}
        </span>
        <em>
          <span style={{ fontWeight: 400 }}>6 months</span>
        </em>
        <span style={{ fontWeight: 400 }}>.</span>
      </p>
      <p>
        <span style={{ fontWeight: 400 }}>
          Notwithstanding the other provisions of this Section, We may retain
          Your Personal Data where such retention is necessary for compliance
          with a legal obligation to which We are subject, or in order to
          protect Your vital interests or the vital interests of another natural
          person.
        </span>
      </p>
      <ol>
        <li style={{ fontWeight: 400 }}>
          <h2>
            <span style={{ fontWeight: 400 }}>Data Security</span>
          </h2>
        </li>
      </ol>
      <p>
        <span style={{ fontWeight: 400 }}>
          We follow generally accepted industry standards to protect the
          information submitted to Us, both during transmission and once We
          receive it. We maintain appropriate administrative, technical and
          physical safeguards to protect Personal Data against accidental or
          unlawful destruction, accidental loss, unauthorised alteration,
          unauthorised disclosure or access, misuse, and any other unlawful form
          of processing of the Personal Data in Our possession. This includes,
          for example, firewalls, password protection and other access and
          authentication controls.
        </span>
      </p>
      <p>
        <span style={{ fontWeight: 400 }}>We use </span>
        <strong>HTTPS</strong>
      </p>
      <p>
        <span style={{ fontWeight: 400 }}>
          HTTPS (Hypertext Transfer Protocol Secure) is an internet
          communication protocol that protects the integrity and confidentiality
          of data between your computer and kantinapp.com.cy. The use of HTTPS
          makes sure your communication with us is:
        </span>
      </p>
      <p>
        <strong>Encrypted</strong>
        <span style={{ fontWeight: 400 }}>
          {" "}
          &mdash; The data you exchange with us is secure from eavesdroppers.
          That means that when you are browsing kantinapp.com.cy, nobody can
          track your activities across multiple pages, or steal the data
          exchange between your computer and kantinapp.com.cy.
        </span>
      </p>
      <p>
        <strong>Integral</strong>
        <span style={{ fontWeight: 400 }}>
          {" "}
          &mdash; The use of HTTPS preserves the integrity of data. Your data
          cannot be modified or corrupted during transfer.
        </span>
      </p>
      <p>
        <strong>Authenticated</strong>
        <span style={{ fontWeight: 400 }}>
          {" "}
          &mdash; HTTPS protocol authenticates your communication with us. This
          ensures that you are always communicating with our servers.
        </span>
      </p>
      <p>
        <span style={{ fontWeight: 400 }}>
          However, no method of transmission over the Internet, or method of
          electronic storage, is 100% secure. We cannot ensure or warrant the
          security of any information You transmit to Us or store on the
          Website, and You do so at Your own risk. We also cannot guarantee that
          such information may not be accessed, disclosed, altered, or destroyed
          by breach of any of Our physical, technical, or managerial safeguards.
          If You believe Your Personal Data has been compromised, please contact
          Us at michalis@kantinapp.info.
        </span>
      </p>
      <p>
        <span style={{ fontWeight: 400 }}>
          If We learn of a security systems breach, then We attempt to notify
          You electronically so that You can take appropriate protective steps.
          We may post a notice through the Website if a security breach occurs.
        </span>
      </p>
      <ol>
        <li style={{ fontWeight: 400 }}>
          <h2>
            <span style={{ fontWeight: 400 }}>Your Data Privacy Rights</span>
          </h2>
        </li>
      </ol>
      <p>
        <span style={{ fontWeight: 400 }}>
          If You are a citizen of the EU, We have summarised the rights that You
          have under the General Data Protection Regulation (GDPR) in this
          Section. Some of the rights are complex, and not all of the details
          have been included in Our summaries. Accordingly, You should read the
          relevant laws and guidance from the regulatory authorities for a full
          explanation of these rights.
        </span>
      </p>
      <p>
        <strong>Right to Access</strong>
        <span style={{ fontWeight: 400 }}>
          . You have the right to confirmation as to whether or not We process
          Your Personal Data and, where We do, access to the Personal Data,
          together with certain additional information. That additional
          information includes details of the purposes of the processing, the
          categories of Personal Data concerned and the recipients of the
          Personal Data. Providing the rights and freedoms of others are not
          affected, We will supply to You a copy of Your Personal Data. The
          first copy will be provided free of charge, but additional copies may
          be subject to a reasonable fee.&nbsp;
        </span>
      </p>
      <p>
        <strong>Right to Rectification.</strong>
        <span style={{ fontWeight: 400 }}>
          {" "}
          You have the right to have any inaccurate Personal Data about You
          rectified and, taking into account the purposes of the processing, to
          have any incomplete Personal Data about You completed.
        </span>
      </p>
      <p>
        <strong>Right to Erasure.</strong>
        <span style={{ fontWeight: 400 }}>
          {" "}
          In some circumstances You have the right to the erasure of Your
          Personal Data without undue delay. Those circumstances include: the
          Personal Data are no longer necessary in relation to the purposes for
          which they were collected or otherwise processed; You withdraw consent
          to consent-based processing; You object to the processing under
          certain rules of applicable data protection law; the processing is for
          direct marketing purposes; and the Personal Data have been unlawfully
          processed.
        </span>
      </p>
      <p>
        <span style={{ fontWeight: 400 }}>
          However, there are exclusions of the right to erasure. The general
          exclusions include where processing is necessary: for exercising the
          right of freedom of expression and information; for compliance with a
          legal obligation; or for the establishment, exercise or defence of
          legal claims.
        </span>
      </p>
      <p>
        <strong>Right to Restrict Processing</strong>
        <span style={{ fontWeight: 400 }}>
          . In some circumstances You have the right to restrict the processing
          of Your Personal Data. Those circumstances are: You contest the
          accuracy of the Personal Data; processing is unlawful but You oppose
          erasure; We no longer need the Personal Data for the purposes of Our
          processing, but You require Personal Data for the establishment,
          exercise or defence of legal claims; and You have objected to
          processing, pending the verification of that objection. Where
          processing has been restricted on this basis, We may continue to store
          Your Personal Data. However, We will only otherwise process it: with
          Your consent; for the establishment, exercise or defence of legal
          claims; for the protection of the rights of another natural or legal
          person; or for reasons of important public interest.
        </span>
      </p>
      <p>
        <strong>Right to Object to Processing</strong>
        <span style={{ fontWeight: 400 }}>
          . You have the right to object to Our processing of Your Personal Data
          on grounds relating to Your particular situation, but only to the
          extent that the legal basis for the processing is that the processing
          is necessary for: the performance of a task carried out in the public
          interest or in the exercise of any official authority vested in Us; or
          the purposes of the legitimate interests pursued by Us or by a third
          party. If You make such an objection, We will cease to process the
          Personal Data unless We can demonstrate compelling legitimate grounds
          for the processing which override Your interests, rights and freedoms,
          or the processing is for the establishment, exercise or defence of
          legal claims.
        </span>
      </p>
      <p>
        <span style={{ fontWeight: 400 }}>
          You have the right to object to Our processing of Your Personal Data
          for direct marketing purposes (including profiling for direct
          marketing purposes). If You make such an objection, We will cease to
          process Your Personal Data for this purpose.
        </span>
      </p>
      <p>
        <span style={{ fontWeight: 400 }}>
          You have the right to object to Our processing of Your Personal Data
          for scientific or historical research purposes or statistical purposes
          on grounds relating to Your particular situation, unless the
          processing is necessary for the performance of a task carried out for
          reasons of public interest.
        </span>
      </p>
      <p>
        <strong>Right to Data Portability</strong>
        <span style={{ fontWeight: 400 }}>
          . To the extent that the legal basis for Our processing of Your
          Personal Data is consent, or that the processing is necessary for the
          performance of a contract to which You are party or in order to take
          steps at Your request prior to entering into a contract, and such
          processing is carried out by automated means, You have the right to
          receive Your Personal Data from Us in a structured, commonly used and
          machine-readable format. However, this right does not apply where it
          would adversely affect the rights and freedoms of others.
        </span>
      </p>
      <p>
        <strong>Right to Complain to a Supervisory Authority</strong>
        <span style={{ fontWeight: 400 }}>
          . If You consider that Our processing of Your Personal Data infringes
          data protection laws, You have a legal right to lodge a complaint with
          a supervisory authority responsible for data protection. You may do so
          in the EU member state of Your habitual residence, Your place of work
          or the place of the alleged infringement.
        </span>
      </p>
      <p>
        <span style={{ fontWeight: 400 }}>
          We hope that you are happy with our services and, in case you want to
          discuss anything, contact us in the first instance. However, you do
          retain a right to file a complaint at any time and, should you wish to
          do so, you can contact the supervisory authority for data protection
          in the Republic of Cyprus, the Office of the Commissioner for Data
          Protection. The contact details for the Office of the Commissioner for
          Data Protection are:
        </span>
      </p>
      <p>&nbsp;</p>
      <p>
        <span style={{ fontWeight: 400 }}>
          Email:&nbsp;commissionerdataprotection.gov.cy
        </span>
      </p>
      <p>
        <span style={{ fontWeight: 400 }}>
          Address:&nbsp;Iasonos 1, 1082 Nicosia, Cyprus
        </span>
        <span style={{ fontWeight: 400 }}>
          <br />
        </span>
        <span style={{ fontWeight: 400 }}>Web: </span>
        <a href="http://www.dataprotection.gov.cy">
          <span style={{ fontWeight: 400 }}>www.dataprotection.gov.cy</span>
        </a>
        <span style={{ fontWeight: 400 }}>&nbsp;</span>
      </p>
      <p>
        <strong>Right to Withdraw Consent</strong>
        <span style={{ fontWeight: 400 }}>
          . To the extent that the legal basis for Our processing of Your
          Personal Data is consent, You have the right to withdraw that consent
          at any time. Withdrawal will not affect the lawfulness of processing
          before the withdrawal.
        </span>
      </p>
      <p>
        <span style={{ fontWeight: 400 }}>
          You may exercise any of Your rights in relation to Your Personal Data
          through written notice to Us. See Our Contact Details at the end of
          this Policy.
        </span>
      </p>
      <ol>
        <li style={{ fontWeight: 400 }}>
          <h2>
            <span style={{ fontWeight: 400 }}>Data Protection</span>
          </h2>
        </li>
      </ol>
      <p>
        <span style={{ fontWeight: 400 }}>
          If You are located in the European Economic Area, Your Personal Data
          will be processed by Planet Caravan Software Ltd.
        </span>
      </p>
      <p>
        <span style={{ fontWeight: 400 }}>
          When We processes Personal Data in the course of providing the
          Website, We will:
        </span>
      </p>
      <ul>
        <li style={{ fontWeight: 400 }}>
          <span style={{ fontWeight: 400 }}>
            process the Personal Data as a Data Processor, only for the purpose
            of providing the Website in accordance with documented instructions
            from You (provided that such instructions are commensurate with the
            functionalities of the Website), and as may subsequently be agreed
            to by You. If We are required by law to Process the Personal Data
            for any other purpose, Planet Caravan Software Ltd will provide You
            with prior notice of this requirement, unless Planet Caravan
            Software Ltd is prohibited by law from providing such notice;
          </span>
        </li>
        <li style={{ fontWeight: 400 }}>
          <span style={{ fontWeight: 400 }}>
            notify You if, in Our opinion, Your instruction for the processing
            of Personal Data infringes applicable Data Protection Legislation;
          </span>
        </li>
        <li style={{ fontWeight: 400 }}>
          <span style={{ fontWeight: 400 }}>
            notify You promptly, to the extent permitted by law, upon receiving
            an inquiry or complaint from You or a Supervisory Authority relating
            to Planet Caravan Software Ltd&rsquo;s Processing of the Personal
            Data;
          </span>
        </li>
        <li style={{ fontWeight: 400 }}>
          <span style={{ fontWeight: 400 }}>
            implement and maintain appropriate technical and organisational
            measures to protect the Personal Data against unauthorised or
            unlawful processing and against accidental loss, destruction,
            damage, theft, alteration or disclosure. These measures shall be
            appropriate to the harm which might result from any unauthorised or
            unlawful processing, accidental loss, destruction, damage or theft
            of Personal Data and appropriate to the nature of the Personal Data
            which is to be protected;
          </span>
        </li>
        <li style={{ fontWeight: 400 }}>
          <span style={{ fontWeight: 400 }}>
            provide You, upon request, with up-to-date attestations, reports or
            extracts thereof where available from a source charged with auditing
            Planet Caravan Software Ltd&rsquo;s data protection practices (e.g.
            external auditors, internal audit, data protection auditors), or
            suitable certifications, to enable You to assess compliance with the
            terms of this Privacy Policy;
          </span>
        </li>
        <li style={{ fontWeight: 400 }}>
          <span style={{ fontWeight: 400 }}>
            notify You promptly upon becoming aware of and confirming any
            accidental, unauthorised, or unlawful processing of, disclosure of,
            or access to the Personal Data;
          </span>
        </li>
        <li style={{ fontWeight: 400 }}>
          <span style={{ fontWeight: 400 }}>
            ensure that its personnel who access the Personal Data are subject
            to confidentiality obligations that restrict their ability to
            disclose the Personal Data; and
          </span>
        </li>
        <li style={{ fontWeight: 400 }}>
          <span style={{ fontWeight: 400 }}>
            upon termination of the Terms, Planet Caravan Software Ltd will
            promptly initiate its purge process to delete or anonymise the
            Personal Data. If You request a copy of such Personal Data within 60
            days of termination, Planet Caravan Software Ltd will provide You
            with a copy of such Personal Data.&nbsp;
          </span>
        </li>
      </ul>
      <p>
        <span style={{ fontWeight: 400 }}>
          We have offices and facilities in Cyprus.The European Commission has
          made an "adequacy decision" with respect to the data protection laws
          of Cyprus. Transfers to Cyprus will be protected by appropriate
          safeguards, namely the use of standard data protection clauses adopted
          or approved by the European Commission. A copy of the use of standard
          protection clauses adopted or approved by the European Commission can
          be obtained through:{" "}
        </span>
        <a href="https://ec.europa.eu/info/law/law-topic/data-protection/international-dimension-data-protection/standard-contractual-clauses-scc_en">
          <span style={{ fontWeight: 400 }}>ec.europa.eu</span>
        </a>
      </p>
      <p>
        <span style={{ fontWeight: 400 }}>
          The hosting facilities for Our Website are situated in{" "}
        </span>
        <span style={{ fontWeight: 400 }}>Germany</span>
        <span style={{ fontWeight: 400 }}>
          . The European Commission has made an "adequacy decision" with respect
          to the data protection laws of Germany. Transfers to{" "}
        </span>
        <span style={{ fontWeight: 400 }}>Germany </span>
        <span style={{ fontWeight: 400 }}>
          will be protected by appropriate safeguards, namely the use of
          standard data protection clauses adopted or approved by the European
          Commission. A copy of the use of standard protection clauses adopted
          or approved by the European Commission can be obtained through:{" "}
        </span>
        <a href="https://ec.europa.eu/info/law/law-topic/data-protection/international-dimension-data-protection/standard-contractual-clauses-scc_en">
          <span style={{ fontWeight: 400 }}>ec.europa.eu</span>
        </a>
      </p>
      <ol>
        <li style={{ fontWeight: 400 }}>
          <h2>
            <span style={{ fontWeight: 400 }}>Your Choices</span>
          </h2>
        </li>
      </ol>
      <p>
        <span style={{ fontWeight: 400 }}>
          In general, if You register on the Website, You may update the
          information You have provided to Us.
        </span>
      </p>
      <p>
        <span style={{ fontWeight: 400 }}>
          When You register on the Website, You may be able to indicate Your
          preferences regarding marketing communications. You may be able to
          change those preferences and select the categories of communications
          that interest You.
        </span>
      </p>
      <p>
        <span style={{ fontWeight: 400 }}>
          You will not be able to opt-out of receiving certain service-related
          or transactional communications relating to the Services. No such
          communication will occur unless you place an order through our
          Services.&nbsp;
        </span>
      </p>
      <ol>
        <li style={{ fontWeight: 400 }}>
          <h2>
            <span style={{ fontWeight: 400 }}>Your Right to Access</span>
          </h2>
        </li>
      </ol>
      <p>
        <span style={{ fontWeight: 400 }}>
          We provide You with reasonable access to the Personal Data that You
          may provide through the Website.
        </span>
      </p>
      <ol>
        <li style={{ fontWeight: 400 }}>
          <h2>
            <span style={{ fontWeight: 400 }}>
              Your Right to Withdraw Consent
            </span>
          </h2>
        </li>
      </ol>
      <p>
        <span style={{ fontWeight: 400 }}>
          At any time, You may legitimately object to the processing of Your
          Personal Data, except if otherwise provided by applicable law. You may
          decline to share certain Personal Data with Us, in which case We may
          not be able to provide to You some of the features and functionality
          of the Website.
        </span>
      </p>
      <ol>
        <li style={{ fontWeight: 400 }}>
          <h2>
            <span style={{ fontWeight: 400 }}>
              Your Right to Update, Correct or Delete
            </span>
          </h2>
        </li>
      </ol>
      <p>
        <span style={{ fontWeight: 400 }}>
          You may update, correct, or delete Your Account Data and preferences
          at any time by accessing Your Account settings page on the Website. If
          You wish to access or amend any other Personal Data We hold about You,
          or to request that We delete any information about You that We have
          obtained from an Integrated Service, You may contact Us. Please note
          that while any changes You make will be reflected in active user
          databases instantly or within a reasonable period of time, We may
          retain all information You submit for backups, archiving, prevention
          of fraud and abuse, analytics, satisfaction of legal obligations, or
          where We otherwise reasonably believe that We have a legitimate reason
          to do so.
        </span>
      </p>
      <p>
        <span style={{ fontWeight: 400 }}>
          We will however retain Your information needed for administrative and
          transactional communications.
        </span>
      </p>
      <ol>
        <li style={{ fontWeight: 400 }}>
          <h2>
            <span style={{ fontWeight: 400 }}>
              No Personal Data From Children
            </span>
          </h2>
        </li>
      </ol>
      <p>
        <span style={{ fontWeight: 400 }}>
          We do not knowingly collect Personal Data from children under 16 years
          of age in the European Union. The Website is not directed to children
          under the age of 16 in the European Union. We will not knowingly allow
          children under 16 in the European Union to register become users of
          the Website.
        </span>
      </p>
      <p>
        <span style={{ fontWeight: 400 }}>
          If You are under 16 in the European Union years of age, You should not
          provide Personal Data to Us.
        </span>
      </p>
      <p>
        <span style={{ fontWeight: 400 }}>
          If We discover that a child under the age of 16 in the European Union
          has provided Us with Personal Data and We do not have parental
          consent, We will immediately delete that child&rsquo;s information.
        </span>
      </p>
      <ol>
        <li style={{ fontWeight: 400 }}>
          <h2>
            <span style={{ fontWeight: 400 }}>Cookie Policy</span>
          </h2>
        </li>
      </ol>
      <p>
        <span style={{ fontWeight: 400 }}>
          A cookie is a file containing an identifier (a string of letters and
          numbers) that is sent by a web server to a web browser and is stored
          by the browser. The identifier is then sent back to the server each
          time the browser requests a page from the server.
        </span>
      </p>
      <p>
        <span style={{ fontWeight: 400 }}>
          Cookies may be either "persistent" cookies or "session" cookies: a
          persistent cookie will be stored by a web browser and will remain
          valid until its set expiry date, unless deleted by the user before the
          expiry date; a session cookie, on the other hand, will expire at the
          end of the user session, when the web browser is closed.
        </span>
      </p>
      <p>
        <span style={{ fontWeight: 400 }}>
          Cookies do not typically contain any information that personally
          identifies a user, but Personal Data that We store about You may be
          linked to the information stored in and obtained from cookies.
        </span>
      </p>
      <h3>
        <span style={{ fontWeight: 400 }}>15.1 Cookie We Use</span>
      </h3>
      <h4>
        <em>
          <span style={{ fontWeight: 400 }}>Session Cookies</span>
        </em>
      </h4>
      <p>
        <span style={{ fontWeight: 400 }}>
          These are temporary cookies that contain no expiration date and&nbsp;
          are deleted after the users browser is closed. You will be required to
          log in again at the next session, where you will be treated as a new
          user
        </span>
      </p>
      <p>
        <strong>Session cookies examples:</strong>
        <span style={{ fontWeight: 400 }}>
          {" "}
          Shopping cart, remembering previous actions, managing and passing
          security tokens, multimedia content player session cookies, load
          balancing session cookies and third party social content plug in
          sharing cookies
        </span>
      </p>
      <h4>
        <em>
          <span style={{ fontWeight: 400 }}>Persistent Cookies</span>
        </em>
      </h4>
      <p>
        <span style={{ fontWeight: 400 }}>
          These are cookies which are saved in your browser until they are
          deleted by the you or are deleted by your browser after they reach
          their expiration date.
        </span>
      </p>
      <p>
        <strong>Persistent cookies examples:</strong>
        <span style={{ fontWeight: 400 }}>
          {" "}
          Authentication, language selection, theme selection, favorites,
          internal site bookmarks, menu preferences, address and payment
          information.
        </span>
      </p>
      <h4>
        <em>
          <span style={{ fontWeight: 400 }}>Functionality Cookies</span>
        </em>
      </h4>
      <p>
        <span style={{ fontWeight: 400 }}>
          Functionality cookies allow us to remember choices that have been made
          by you in order to create an experience that&rsquo;s made to fit.
          These cookies safely store authentication and identification
          information, for example your language selection or region.
        </span>
      </p>
      <h4>
        <em>
          <span style={{ fontWeight: 400 }}>Performance Cookies</span>
        </em>
      </h4>
      <p>
        <span style={{ fontWeight: 400 }}>
          Performance cookies allow us to collect information, such as the most
          used pages or error messages, in order to understand how you use our
          website and enable us to create a better user experience. All
          information collected by performance cookies in anonymous.
        </span>
      </p>
      <h4>
        <em>
          <span style={{ fontWeight: 400 }}>Affiliate Tracking Cookies</span>
        </em>
      </h4>
      <p>
        <span style={{ fontWeight: 400 }}>
          When you come to us via an affiliate link a cookie is stored on your
          browser and this cookie is used to track the affiliate referral. We
          require this cookie in order to pay commission to the referrer.
        </span>
      </p>
      <h3>
        <span style={{ fontWeight: 400 }}>15.2 Purpose Of Using Cookies</span>
      </h3>
      <p>
        <strong>Authentication</strong>
        <span style={{ fontWeight: 400 }}>
          {" "}
          - We use cookies to identify You when You visit Our Website and as You
          navigate Our Website;
        </span>
      </p>
      <p>
        <strong>Status</strong>
        <span style={{ fontWeight: 400 }}>
          {" "}
          - We use cookies to help Us determine if You are logged into Our
          Website;
        </span>
      </p>
      <p>
        <strong>Personalization</strong>
        <span style={{ fontWeight: 400 }}>
          {" "}
          - We use cookies to store information about Your preferences and to
          personalize the Website for You;
        </span>
      </p>
      <p>
        <strong>Security</strong>
        <span style={{ fontWeight: 400 }}>
          {" "}
          - We use cookies as an element of the security measures used to
          protect user accounts, including preventing fraudulent use of login
          credentials, and to protect Our Website and Services generally;
        </span>
      </p>
      <p>
        <strong>Analysis</strong>
        <span style={{ fontWeight: 400 }}>
          {" "}
          - We use cookies to help Us to analyze the use and performance of Our
          Website and Services;
        </span>
      </p>
      <p>
        <strong>Cookie Consent</strong>
        <span style={{ fontWeight: 400 }}>
          {" "}
          - We use cookies to store Your preferences in relation to the use of
          cookies more generally.
        </span>
      </p>
      <h3>
        <span style={{ fontWeight: 400 }}>
          15.3 Cookies Used by Our Service Providers
        </span>
      </h3>
      <p>
        <span style={{ fontWeight: 400 }}>
          Our service providers use cookies and those cookies may be stored on
          Your computer when You visit Our Website.
        </span>
      </p>
      <p>
        <strong>Google Analytics</strong>
        <span style={{ fontWeight: 400 }}>
          . We use Google Analytics to analyse the use of Our Website. Google
          Analytics gathers information about Website use by means of cookies.
          The information gathered relating to Our Website is used to create
          reports about the use of Our Website. Google's privacy policy is
          available at:{" "}
        </span>
        <a href="https://www.google.com/policies/privacy/">
          <span style={{ fontWeight: 400 }}>
            https://www.google.com/policies/privacy/
          </span>
        </a>
        <span style={{ fontWeight: 400 }}>.</span>
      </p>
      <h3>
        <span style={{ fontWeight: 400 }}>15.4 Managing Cookies</span>
      </h3>
      <p>
        <span style={{ fontWeight: 400 }}>
          You can disable cookie tracking and learn more about the privacy
          policies of the third parties We use by visiting the following links:
        </span>
      </p>
      <p>
        <a href="https://tools.google.com/dlpage/gaoptout">
          <span style={{ fontWeight: 400 }}>Opt Out</span>
        </a>
      </p>
      <p>
        <a href="https://policies.google.com/privacy">
          <span style={{ fontWeight: 400 }}>Privacy Policy</span>
        </a>
      </p>
      <ol>
        <li style={{ fontWeight: 400 }}>
          <h2>
            <span style={{ fontWeight: 400 }}>Changes And Updates</span>
          </h2>
        </li>
      </ol>
      <p>
        <span style={{ fontWeight: 400 }}>
          Please revisit this page periodically to stay aware of any changes to
          this Privacy Policy, which We may update from time to time. If We
          modify the Privacy Policy, We will make it available through the
          Website, and indicate the date of the latest revision. Any material
          changes will be posted on this Website and will come into effect 30
          Days after their posting.
        </span>
      </p>
      <p>
        <span style={{ fontWeight: 400 }}>
          In the event that the modifications materially alter Your rights or
          obligations hereunder, We will make reasonable efforts to notify You
          of the change. For example, We may send a message to Your email
          address, if We have one on file, or generate a pop-up or similar
          notification when You access the Website for the first time after such
          material changes are made. If You do not refuse the changes in writing
          before they become effective, this shall mean that You have consented
          to the Privacy Policy as changed. Your continued use of the Website
          after the revised Privacy Policy has become effective indicates that
          You have read, understood and agreed to the current version of the
          Privacy Policy.
        </span>
      </p>
      <ol>
        <li style={{ fontWeight: 400 }}>
          <h2>
            <span style={{ fontWeight: 400 }}>Assignment</span>
          </h2>
        </li>
      </ol>
      <p>
        <span style={{ fontWeight: 400 }}>
          We may change Our ownership or corporate organisation while providing
          the Website and Services. We may also sell certain assets associated
          with the Website. As a result, please be aware that in such event, We
          may transfer some or all of Your information to a company acquiring
          all or part of Our assets or to another company with which We have
          merged. Under such circumstances We would, to the extent possible,
          require the acquiring party to follow the practices described in this
          Privacy Policy, as it may be amended from time to time. Nevertheless,
          We cannot promise that an acquiring company or the merged company will
          have the same privacy practices or treat Your information the same as
          described in this Privacy Policy.
        </span>
      </p>
      <ol>
        <li style={{ fontWeight: 400 }}>
          <h2>
            <span style={{ fontWeight: 400 }}>Our Details</span>
          </h2>
        </li>
      </ol>
      <p>
        <span style={{ fontWeight: 400 }}>
          If you have questions or concerns regarding this policy or if you need
          to make a request please contact us at:
        </span>
      </p>
      <table>
        <tbody>
          <tr>
            <td>
              <p>
                <strong>Mail:</strong>
              </p>
            </td>
            <td>
              <p>
                <span style={{ fontWeight: 400 }}>
                  Michail Karaoli 8, Anemomylos Building, 4
                </span>
                <span style={{ fontWeight: 400 }}>th</span>
                <span style={{ fontWeight: 400 }}>
                  {" "}
                  Floor 1095 Nicosia Cyprus
                </span>
              </p>
            </td>
          </tr>
          <tr>
            <td>
              <p>
                <strong>E-mail:</strong>
              </p>
            </td>
            <td>
              <p>
                <span style={{ fontWeight: 400 }}>michalis@kantinapp.info</span>
              </p>
            </td>
          </tr>
        </tbody>
      </table>
      <p>
        <br />
        <br />
        <br />
      </p>
    </div>
  );
};

export default PrivacyPolicyComponent;
