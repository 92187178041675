import React from "react";

// import { Link } from 'react-router-dom'

// import { Button } from 'primereact/button';
// import { Sidebar } from 'primereact/sidebar';

import "primereact/resources/themes/nova-light/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";

import UserStore from "../stores/UserStore";

class HeaderLogin extends React.Component {
  constructor(props) {
    super(props);
    if (UserStore.isLoggedIn) {
      window.location.href = "/";
    }
  }

  render() {
    return (
      <div className="headerLogin">
        <img alt="logo" src={require("../images/logo.svg")} />
      </div>
    );
  }
}

export default HeaderLogin;
