import React from "react";
import { observer } from "mobx-react";

import UserStore from "../stores/UserStore";

import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { Password } from "primereact/password";

import "primereact/resources/themes/nova-light/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import { Dialog } from "primereact/dialog";

import { callApi } from "../services/ApiService";
import HeaderLogin from "../components/HeaderLogin";
// import Auth from '../services/Auth';
import Register from "../components/Register";
import ForgotPassword from "../components/ForgotPassword";
import GoogleLogin from "react-google-login";
import FacebookLogin from "react-facebook-login";
import AppleLogin from "react-apple-login";

class Login extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: "",
      loading: true,
      username: "",
      password: "",
      registerModal: false,
    };
    this.passwordRef = React.createRef(null);
  }

  componentDidMount() {
    this.loginWithApple();
  }
  loginWithApple = () => {
    let searchURL = this.props.location.search;
    let params = new URLSearchParams(searchURL);

    if (params.get("provider") === "apple") {
      if (params.get("error") === null) {
        UserStore.isLoggedIn = true;
        UserStore.access_token = params.get("access_token");
        UserStore.name = params.get("name");
        localStorage.setItem("UserStore", JSON.stringify(UserStore));
        window.location.href = "/";
      } else {
        this.setState({ error: params.get("error") });
      }
    }
  };

  submitLogin = () => {
    callApi("login", "post", false, [
      { username: this.state.username },
      { password: this.state.password },
    ])
      .then((data) => {
        if (typeof data == "undefined") {
          UserStore.isLoggedIn = false;
          localStorage.clear();
          this.setState({ error: "Error. Please try again." });
        } else if (data.status === "fail") {
          this.setState({ error: data.message });
        } else {
          UserStore.isLoggedIn = true;
          UserStore.access_token = data.access_token;
          UserStore.name = data.name;
          localStorage.setItem("UserStore", JSON.stringify(UserStore));
          window.location.href = "/";
        }
      })
      .catch((e) => {
        this.setState({ error: "Error. Please try again.", loading: false });
      });
  };

  providerLogin = (response, provider) => {
    if (response.status !== "unknown") {
      let userProfile = [];
      if (provider === "facebook") {
        userProfile = [
          { name: response.name },
          { provider_id: response.id },
          { email: response.email },
        ];
      } else if (provider === "google") {
        userProfile = [
          { name: response.profileObj.name },
          { provider_id: response.googleId },
          { email: response.profileObj.email },
        ];
      }

      callApi(`login/${provider}`, "post", false, userProfile)
        .then((response) => {
          if (!response.error) {
            UserStore.isLoggedIn = true;
            UserStore.access_token = response.access_token;
            UserStore.name = response.name;
            localStorage.setItem("UserStore", JSON.stringify(UserStore));
            window.location.href = "/";
          } else {
            this.setState({ error: response.error });
          }
        })
        .catch((err) => {});
    }
  };

  handleModalClose = () => {
    this.setState({ error: "" });
  };

  hideRegisterModal = () => {
    this.setState({ registerModal: false });
  };

  hideForgotPasswordModal = () => {
    this.setState({ forgotPasswordModal: false });
  };

  render() {
    return (
      <div>
        <HeaderLogin />

        <div className="loginTitle" style={{ display: "flex" }}>
          <div className="circle">
            <img alt="login" src={require("../images/login.png")} />
          </div>
          <h2>Σύνδεση / Login</h2>
        </div>
        <div className="p-d-flex p-flex-column">
          <div className="p-as-center p-mb-3 ">
            <InputText
              className={"txt-input"}
              color={"black"}
              placeholder={"Email*"}
              value={this.state.username}
              onChange={(e) => this.setState({ username: e.target.value })}
            />
          </div>
          <div className="p-as-center p-mb-3">
            <Password
              ref={this.passwordRef}
              placeholder={"Password"}
              className={"txt-input"}
              value={this.state.password}
              onChange={(e) => this.setState({ password: e.target.value })}
              feedback={false}
            />
          </div>
          <div style={{ width: "15rem" }} className="p-as-center p-mb-3">
            <Button
              className="forgotPasswordButton"
              label="Forgot password"
              onClick={() => this.setState({ forgotPasswordModal: true })}
            />
          </div>
          <ForgotPassword
            forgotPasswordModal={this.state.forgotPasswordModal}
            hideForgotPasswordModal={this.hideForgotPasswordModal}
          />
          <div style={{ width: "15rem" }} className="p-as-center p-mb-2">
            <Button
              className="loginButton"
              label="Login"
              onClick={this.submitLogin}
            />
          </div>
          <div style={{ width: "15rem" }} className="p-as-center p-mb-2">
            <Button
              style={{ width: "100%", paddingTop: 5, paddingBottom: 5 }}
              label="Register"
              className="p-button-success"
              onClick={() => this.setState({ registerModal: true })}
            />
          </div>
          <Register
            registerModal={this.state.registerModal}
            hideRegisterModal={this.hideRegisterModal}
          />
          <div className="loginButtons">
            <div className="p-as-center p-mb-2">
              <GoogleLogin
                className="google"
                clientId="286562646272-urup1ios7s1hmtab1bavtqt03dkktb85.apps.googleusercontent.com"
                buttonText="Sign in with Google"
                onSuccess={(response) => this.providerLogin(response, "google")}
                cookiePolicy={"single_host_origin"}
              />
            </div>
            <div className="p-as-center p-mb-2">
              <FacebookLogin
                autoLoad={false}
                appId="891790254561317"
                fields="id,email,name"
                scope="email"
                icon="fa-facebook"
                disableMobileRedirect={true}
                callback={(response) =>
                  this.providerLogin(response, "facebook")
                }
              />
            </div>
            <div className="p-as-center p-mb-2">
              <AppleLogin
                designProp={{ height: "60", width: "230" }}
                callback={(appleResponse) => {}}
                clientId="com.tabsandspacesagency.canteens"
                redirectURI="https://kantinapp-backend-staging.tabsandspaces.agency/api/apple-login"
                responseType="code id_token"
                responseMode="form_post"
                scope="name email"
              />
            </div>
          </div>
        </div>
        <Dialog
          closable
          header="Login Error"
          onHide={() => this.handleModalClose()}
          visible={this.state.error ? true : false}
          style={{ width: "50vw" }}
        >
          {this.state.error}
        </Dialog>
      </div>
    );
  }
}

export default observer(Login);
