import React from "react";
import Header from "../components/Header";
import UserStore from "../stores/UserStore";
import HeaderLogin from "../components/HeaderLogin";
import { Link } from "react-router-dom";
import PrivacyPolicyComponent from "../components/PrivacyPolicyComponent";

const PrivacyPolicy = () => {
  return (
    <div>
      {UserStore.isLoggedIn == true ? (
        <Header />
      ) : (
        <div style={{marginBottom: "-50px"}}>
          <HeaderLogin />
        </div>
      ) }
      <h2 style={{ marginTop: 70 }} className="cartTitle">
          <Link to="/">
            <button
              style={{
                cursor: "pointer",
                borderStyle: "none",
              }}
              className="pi pi-angle-left cartButton"
            ></button>
          </Link>
          <span className="cartTitleSpan">Privacy Policy</span>
        </h2>
      <PrivacyPolicyComponent />
    </div>
  );
};

export default PrivacyPolicy;
