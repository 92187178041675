import React, { useState } from "react";
import { SelectButton } from "primereact/selectbutton";
// import UserStore from '../stores/UserStore';

const SelectType = () => {
  const [value, setValue] = useState(0);
  const options = [
    { label: "Takeaway", value: 0 },
    // {label:'Delivery', value: 1},
  ];

  return (
    <div>
      <div className="content-section order-type">
        <SelectButton
          value={value}
          options={options}
          onChange={(e) => setValue(e.value)}
        />
        {/* {UserStore.data.order_type = value} */}
        {/* {console.log(UserStore.data.order_type)} */}
      </div>
    </div>
  );
};
export default SelectType;
